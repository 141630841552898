<template>
    <v-container fluid>
        <PageHeaderSlot> </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            enableSearch
            searchPlaceholder="搜尋學生編號、學生姓名、電話"
            @search-clicked="searchKeyword($event)"
            @search-input="tableSearchKey = $event"
            enableAdvancedSearch
            @advanced-search-clicked="openAdvancedSearchDialog($event)"
            enableClearAll
            @all-search-cleared="clearSearch()"
            @refresh-clicked="refreshData()"
            enableRefresh
            @options-update="onTableOptionsChange($event)"
            actionViewRouteLink="PayTuitionPayment"
            enableMultiTypeSearch
            :searchTypeOptions="searchTypeOptions"
            :searchType.sync="tableSearchType"
            :initSearchKeyword="querySearch"
        ></Datatable>

        <DialogLayout
            :openDialog="advancedDialogOpen"
            :maxWidth="910"
            title="進階搜尋"
            enableScroll
            confirmBtnText="搜尋"
            @close="handleAdvancedSearchDialogClose($event)"
        >
            <v-row class="ma-0">
                <v-col cols="12" sm="6">
                    <FormSelect 
                        label="學生年級"
                        :fieldValue.sync="filter.grade"
                        :options="$gradeOptions"
                        placeholder="選擇學生年級"
                        hideDetails
                        dense
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <FormInput 
                        label="學校"
                        :fieldValue.sync="filter.school"
                        placeholder="學校"
                        hideDetails
                        dense
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <FormSelect 
                        label="會員等級"
                        :fieldValue.sync="filter.memberLevel"
                        :options="$memberOptions"
                        placeholder="選擇會員等級"
                        hideDetails
                        dense
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <FormDatePicker 
                        label="註冊年份 & 月份"
                        placeholder="選擇註冊年份 & 月份"
                        hideDetails
                        :dateValue.sync="filter.regYearMonth"
                        isYearMonthOnly
                        dense
                        disabledActionButtons
                    />
                </v-col>
            </v-row>
        </DialogLayout>
    </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue';
import DialogLayout from '@/components/layout/DialogLayout.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import FormInput from '@/components/formField/FormInput.vue';

export default {
    name: 'PayTuitionList',
    components: {
        Datatable,
        FormSelect,
        FormDatePicker,
        DialogLayout,
        FormInput
    },
    computed: {
        query() {
            return this.$route.query;
        },
        querySearch() {
            return this.$route.query.search || '';
        }
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSearchKey: '',
        tableSearchType: 'id',
        advancedDialogOpen: false,

        /* 進階搜尋 */
        filter: {
            grade: '',
            school: '',
            memberLevel: '',
            regYearMonth: '',
        },
        filterSelected: { // 禁咗搜尋之後save
            grade: '',
            school: '',
            memberLevel: '',
            regYearMonth: '',
        },

        // --- static data
        tableHeaders: [
            { value: 'student_id', text: '學生編號' },
            { value: 'grade', text: '年級' },
            { value: 'student_name', text: '姓名' },
            { value: 'school', text: '學校' },
            { value: 'phone', text: '電話' },
            { value: 'reg_date', text: '註冊日期' },
            { value: 'order_status', text: '本月訂單狀態', align: 'center' },
            { value: 'next_order_status', text: '下月訂單狀態', align: 'center' },
            { value: 'actionApplyCourse', text: '', align: 'end' },
        ],
        searchTypeOptions: [
            { value: 'id', text: '學生編號' },
            { value: 'name', text: '學生姓名' },
            { value: 'phone', text: '電話' },
        ],
    }),
    methods: {
        async getPayTuitionData(regenUrl = true) {
            if (regenUrl === true) {
                this.regenerateURL();
            }

            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                };

                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const filterItems = [];
                if (this.$validate.DataValid(this.tableSearchKey)) {
                    const keyword = this.tableSearchKey.trim();

                    if (this.$validate.DataValid(this.tableSearchType)) {
                        if (this.tableSearchType === 'id') {
                            if (this.$validate.isValidId(keyword)) {
                                const id = keyword.substring(1);
                                filterItems.push({ key: 'id', value: parseInt(id) });
                            } else if (this.$validate.regexNumber(keyword)) {
                                filterItems.push({ key: 'id', value: parseInt(keyword) });
                            }
                        } else {
                            filterItems.push({ key: this.tableSearchType, value: keyword });
                        }
                    } else {
                        payload.search = keyword;
                    }
                }

                if (this.$validate.DataValid(this.filterSelected.grade)) {
                    filterItems.push({ key: 'grade', value: this.filterSelected.grade });
                }

                if (this.$validate.DataValid(this.filterSelected.school)) {
                    filterItems.push({ key: 'school', value: this.filterSelected.school });
                }

                if (this.$validate.DataValid(this.filterSelected.memberLevel)) {
                    filterItems.push({ key: 'level', value: this.filterSelected.memberLevel });
                }

                if (this.$validate.DataValid(this.filterSelected.regYearMonth)) {
                    filterItems.push({ key: 'create_date', value: this.filterSelected.regYearMonth });
                }
                if (filterItems.length) {
                    payload.filter_item = filterItems;
                }
                

                const { data, total } = await this.$Fetcher.GetPayTuitions(payload);
                this.tableItemTotal = total;

                this.tableData = data.map(el => {
                    return {
                        id: el.student_id,
                        student_id: el.student_id,
                        grade: el.grade,
                        student_name: el.student_name,
                        phone: el.phone,
                        reg_date: el.reg_date,
                        order_status: el.order_status,
                        next_order_status: el.next_order_status,
                        school: el.student_school_name
                    }
                })
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        searchKeyword(keyword) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = keyword.trim();
            this.getPayTuitionData();
        },
        clearSearch() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = '';
            this.tableSearchType = this.searchTypeOptions[0].value;
            this.filterSelected.grade = '';
            this.filterSelected.school = '';
            this.filterSelected.memberLevel = '';
            this.filterSelected.regYearMonth = '';
            this.getPayTuitionData();
        },
        refreshData() {
            this.tableLimit = 10;
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getPayTuitionData();
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1;
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getPayTuitionData();
        },
        openAdvancedSearchDialog(allow) {
            if (this.tableLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: '',
                });

                return;
            }

            if (allow === true) {
                this.filter.grade = this.filterSelected.grade;
                this.filter.school = this.filterSelected.school;
                this.filter.memberLevel = this.filterSelected.memberLevel;
                this.filter.regYearMonth = this.filterSelected.regYearMonth;
                this.advancedDialogOpen = true;
            }
        },
        handleAdvancedSearchDialogClose(action) {
            if (action === true) {
                this.filterSelected.grade = this.filter.grade;
                this.filterSelected.school = this.filter.school;
                this.filterSelected.memberLevel = this.filter.memberLevel;
                this.filterSelected.regYearMonth = this.filter.regYearMonth;
                this.tablePage = 1;
                this.tableItemTotal = 0;
                this.getPayTuitionData();
            }
            this.advancedDialogOpen = false;
        },


        getQuery() {
            if (Object.keys(this.query).length > 0) {
                if (this.$validate.DataValid(this.query.searchType)) {
                    this.tableSearchType = this.query.searchType;
                }

                if (this.$validate.DataValid(this.querySearch)) {
                    this.tableSearchKey = this.querySearch;
                }

                if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
                    this.tablePage = parseInt(this.query.page);
                }

                if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
                    this.tableLimit = parseInt(this.query.limit);
                }

                if (this.$validate.DataValid(this.query.grade)) {
                    this.filterSelected.grade = this.query.grade;
                }
                if (this.$validate.DataValid(this.query.school)) {
                    this.filterSelected.school = this.query.school;
                }
                if (this.$validate.DataValid(this.query.level)) {
                    this.filterSelected.memberLevel = this.query.level;
                }
                if (this.$validate.DataValid(this.query.date)) {
                    this.filterSelected.regYearMonth = this.query.date;
                }
            }
        },

        regenerateURL() {
            const q = {
                searchType: this.tableSearchType,
                search: this.tableSearchKey.trim(),
                page: (this.tableLimit !== 10 || this.tablePage !== 1) ? this.tablePage : null,
                limit: (this.tableLimit !== 10 || this.tablePage !== 1) ? this.tableLimit : null,
                grade: this.filterSelected.grade,
                school: this.filterSelected.school,
                level: this.filterSelected.memberLevel,
                date: this.filterSelected.regYearMonth
            }

            const newQuery = this.$common.diffQuery(q, this.query);
            if (newQuery) {
                this.$router.replace({ query: newQuery });
            }
        },

        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: PayTuitionList');
            this.getQuery();
            this.tableLoading = false;
            await this.getPayTuitionData(false);
        }
    },
    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.tableLoading = true;
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },
}
</script>