<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>

        <v-toolbar flat class="custom-table-toolbar mb-6 no-wrap attendance-toolbar"  height="auto">
            <v-toolbar-items class="toolbar-left-items flex-grow-1 justify-space-between">
                <FormDatePicker
                    :dateValue.sync="selectedYearMonth"
                    dense
                    hideDetails
                    customInputClass="mr-3 mt-2 mb-3 search-input-field"
                    :disabled="tableLoading"
                    @changed="searchWithYearMonth()"
                    isYearMonthOnly
                    disableClear
                ></FormDatePicker>

                <div class="attendance-toolbar__item">總課堂數量：{{ totalLessons }}</div>
                <div class="attendance-toolbar__item">出席：{{ attends }}</div>
                <div class="attendance-toolbar__item">缺席：{{ absents }}</div>
                <div class="attendance-toolbar__item">未上堂：{{ pending }}</div>
            </v-toolbar-items>
        </v-toolbar>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            enableClearAll
            @all-search-cleared="clearSearch()"
            @refresh-clicked="refreshData()"
            enableRefresh
            @options-update="onTableOptionsChange"
            enableSearchDate
            datePlaceholder="上堂日期"
            @date-selected="searchAttendDate"
        ></Datatable>
    </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';

export default {
    name: 'StudentAttendance',
    components: {
        Datatable,
        FormDatePicker
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        }
    },
    data: () => ({
        tableHeaders: [
            { value: 'date', text: '上堂日期' },
            { value: 'timeslot', text: '時間' },
            { value: 'course_code', text: '課程編號' },
            { value: 'course_name', text: '課程名稱' },
            { value: 'attendance', text: '出席狀態', align: 'center' },
        ],

        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSearchOrderDate: '',

        selectedYearMonth: '',
        totalLessons: 0,
        attends: 0,
        absents: 0,
        pending: 0
    }),
    methods: {
        async getAttendanceData() {
            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                    filter_item: [],
                };

                if (this.$validate.DataValid(this.tableSearchOrderDate)) {
                    this.selectedYearMonth = this.tableSearchOrderDate.substring(0, 7);
                    payload.filter_item.push({ key: 'date', value: this.tableSearchOrderDate });
                } else {
                    if (!this.selectedYearMonth) {
                        this.selectedYearMonth = today.substring(0, 7);
                    }
                  
                    const year = parseInt(this.selectedYearMonth.substring(0, 4));
                    const month = parseInt(this.selectedYearMonth.substring(5, 7));
                    const startDate = new Date(year, month - 1, 1);
                    payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(startDate)});
                    const lastDateOfMonth = new Date(year, month, 0);
                    payload.filter_item.push({ key: 'end_date', value: this.$formatter.formatDate(lastDateOfMonth)});
                }

                const { data, total , attend, absent, pending } = await this.$Fetcher.GetStudentAttendances(this.id, payload);
                this.tableItemTotal = total;
                this.tableData = data.map(el => {
                    return {
                        id: el.lesson_id,
                        date: el.date,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        course_code: el.course_code,
                        course_name: el.course_name,
                        attendance: el.attend_status
                    }
                })

                if (this.tablePage <= 1) {
                    this.totalLessons = total;
                    this.attends = attend;
                    this.absents = absent;
                    this.pending = pending;
                }

                this.tableLoading = false;
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
                this.tableLoading = false;
                this.totalLessons = 0;
                this.attends = 0;
                this.absents = 0;
                this.pending = 0;
            }
        },
        searchAttendDate(date) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchOrderDate = date;
            this.getAttendanceData();
        },
        clearSearch() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchOrderDate = '';
            this.getAttendanceData();
        },
        refreshData() {
            this.tableLimit = 10;
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getAttendanceData();
        },
        
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getAttendanceData();
        },

        searchWithYearMonth() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getAttendanceData();
        },

        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: StudentAttendance');
            if (this.$validate.DataValid(this.id)) {
                await this.getAttendanceData();
            }
        }
    },
    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.$store.dispatch('toggleLoadingPage', true);
        if (this.$validate.DataValid(this.id)) {
            this.$store.dispatch('toggleLoadingPage', false);
        }
        this.tableLoading = true;
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },
    created() {
        const today = this.$formatter.formatDate(new Date());
        this.selectedYearMonth = today.substring(0, 7);
    }
}
</script>

<style lang="scss">
.attendance-toolbar {
    & .toolbar-left-items {
        width: 100%;
        max-width: 100% !important;
        align-items: center;
    }
}

.attendance-toolbar__item {
    padding: 1em;
    color: #505050;
    font-size: .875rem;
    font-weight: 500;
}

@media(max-width: 992px) {
    .attendance-toolbar .custom-input {
        width: 100% !important;
        max-width: unset !important;
    }

    .attendance-toolbar__item {
        width: 25%;
    }
}

@media(max-width: 550px) {
    .attendance-toolbar__item {
        width: 50%;
    }
}
</style>