export default {
    install(Vue, option) {
        Vue.prototype.$common = new (function () {
            const isDev = (window.location.hostname).includes('localhost') || (window.location.hostname).includes('innpressionhost');

            this.log = value => {
                if (isDev) {
                    console.log(value)
                }
            }

            this.error = value => {
                if (isDev) {
                    console.error(value)
                }
            }

            this.logData = (data, title = '') => {
                if (isDev) {
                    if (title !== '') {
                        console.log(`------------- ${title} -------------`);
                    }
                    console.log(JSON.parse(JSON.stringify(data)));
                }
            }

            this.duplicateData = data => {
                return JSON.parse(JSON.stringify(data));
            }

            this.diffQuery = (newQuery, oldQuery) => {
                Object.keys(newQuery).forEach(key => {
                    if (newQuery[key] === null ||
                        newQuery[key] === '' ||
                        key === 'searchType' && !newQuery['search']
                    ) {
                        delete newQuery[key];
                    }
                })

                if (Object.keys(newQuery).length !== Object.keys(oldQuery).length) {
                    return newQuery;
                }

                let diff = false;
                const keys = Object.keys(newQuery);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (newQuery[key] != oldQuery[key]) {
                        diff = true;
                        break;
                    }
                }

                if (diff) {
                    return newQuery;
                } else {
                    return null;
                }
            }

            // ============ File Function =================
            this.getAcceptFileFormat = type => {
                let fileFormatStr = "";
                let fileFormatArr = [];

                if (type === 'image') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png"
                    fileFormatArr = ['png', 'jpeg', 'jpg'];
                } else if (type === 'video') {
                    fileFormatStr = "video/mp4,video/webm"
                    fileFormatArr = ['mp4', 'webm'];
                } else if (type === 'imageWithPDF') {
                    fileFormatStr = "image/jpeg,image/jpg,image/png,application/pdf"
                    fileFormatArr = ['png', 'jpeg', 'jpg', 'pdf'];
                }

                if (Vue.prototype.$validate.DataValid(fileFormatStr) && Vue.prototype.$validate.DataValid(fileFormatArr)) {
                    return {
                        formatStr: fileFormatStr,
                        formatArr: fileFormatArr
                    }
                }

                return null;
            }

            // ============ Course Function =================
            this.getStartTimeOptions = () => {
                const startTime = Vue.prototype.$settings.startTime;
                const endTime = Vue.prototype.$settings.endTime;
                const list = [];
                const endTimeDate = new Date(`2023-01-01T${endTime}:00+08:00`);
                const tempDate = new Date(`2023-01-01T${startTime}:00+08:00`);

                while (tempDate.getTime() < endTimeDate.getTime()) {
                    list.push(`${Vue.prototype.$formatter.pad(tempDate.getHours())}:${Vue.prototype.$formatter.pad(tempDate.getMinutes())}`);
                    tempDate.setTime(tempDate.getTime() + Vue.prototype.$settings.interval * 60 * 1000);
                }

                return list;
            }

            this.getEndTimeOptions = (startTime) => {
                if (!startTime) {
                    return [];
                }

                const endTime = Vue.prototype.$settings.endTime;
                const endTimeDate = new Date(`2023-01-01T${endTime}:00+08:00`);
                const tempDate = new Date(`2023-01-01T${startTime}:00+08:00`);

                const list = [];
                while (tempDate.getTime() <= endTimeDate.getTime()) {
                    tempDate.setTime(tempDate.getTime() + Vue.prototype.$settings.interval * 60 * 1000);
                    if (tempDate.getTime() <= endTimeDate.getTime()) {
                        list.push(`${Vue.prototype.$formatter.pad(tempDate.getHours())}:${Vue.prototype.$formatter.pad(tempDate.getMinutes())}`);
                    }
                }

                return list;
            }
        })()
    }
}