<template>
    <v-container fluid>
        <PageHeaderSlot>
            <template slot="top-right-slot">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateCourse' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增課程
                </v-btn>
            </template>
        </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            @options-update="onTableOptionsChange"
            actionViewRouteLink="CourseDetails"
        ></Datatable>
    </v-container>
</template>

<script>
import { mdiPlusCircleOutline } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'CourseList',
    components: {
        Datatable,
    },
    data: () => ({
        icon: {
            mdiPlusCircleOutline
        },
        tableHeaders: [
            // { value: 'course_code', text: '課程編號' },
            { value: 'grade', text: '年級' },
            { value: 'course_category', text: '課程類別' },
            { value: 'tutor_school', text: '分校' },
            { value: 'actionView', text: '', align: 'end' },
        ],

        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
    }),
    methods: {
        async getCourseData() {
            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                    join_school_data: true,
                    join_category_data: true,
                };

                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const { data, total } = await this.$Fetcher.GetCourses(payload);
                this.tableData = data.map(el => {
                    return {
                        id: el.id,
                        course_code: el.course_code,
                        grade: el.grade,
                        course_category: this.$validate.DataValid(el.category_data) ? el.category_data.name : '',
                        tutor_school: this.$validate.DataValid(el.school_datas) ? el.school_datas.map(el => el.name).join(', ') : '',
                    }
                })
                
                this.tableItemTotal = total;
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getCourseData();
        },
        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: CourseList');
            await this.getCourseData();
        }
    },
  
    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.tableLoading = true;
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },
}
</script>