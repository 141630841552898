<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton>
            <template slot="bottom-right-slot">
                <v-btn text depressed class="primary ml-2" @click.prevent="save()" :loading="$store.getters.isLoading">新增</v-btn>
            </template>
        </PageHeaderSlot>

        <v-form ref="form" @submit.prevent>
            <v-card>
                <v-card-text class="px-2">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect
                                label="課程"
                                :fieldValue.sync="formData.course_id"
                                :options="courseOptions"
                                required
                                placeholder="選擇課程"
                                dense
                                @changed="generateTimetablePreview()"
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput
                                label="課程編號"
                                :fieldValue.sync="formData.course_code"
                                required
                                placeholder="課程編號"
                                dense
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput
                                label="課程名稱"
                                :fieldValue.sync="formData.course_name"
                                required
                                placeholder="課程名稱"
                                dense
                                @input-value="generateTimetablePreview()"
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormInput 
                                type="number"
                                label="價錢" 
                                placeholder="價錢"
                                :fieldValue.sync="formData.price" 
                                dense
                                prefix="HK$"
                                suffix="/堂"
                                required
                            ></FormInput>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect
                                label="導師"
                                :fieldValue.sync="formData.tutor_id"
                                :options="tutorOptions"
                                required
                                placeholder="選擇導師"
                                dense
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect
                                label="課室"
                                :fieldValue.sync="formData.classroom_id"
                                :options="classroomOptions"
                                required
                                placeholder="選擇課室"
                                dense
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <CourseColorPicker
                                label="時間表顏色"
                                :fieldValue.sync="formData.color"
                                placeholder="選擇時間表顏色"
                                required
                            ></CourseColorPicker>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect
                                label="薪酬計算法"
                                :fieldValue.sync="formData.salary_method"
                                :options="salaryMethodOptions"
                                required
                                placeholder="選擇薪酬計算法"
                                dense
                            ></FormSelect>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <FormSelect
                                label="人數上限"
                                :fieldValue.sync="formData.max_student"
                                :options="maxStudentOptions"
                                required
                                placeholder="選擇人數上限"
                                dense
                            ></FormSelect>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col cols="12" sm="6" md="4" lg="3">
                            <label class="input-label">課堂生成設定</label>
                            <v-radio-group 
                                name="lessonType"
                                v-model="lessonType"
                                row
                                :readonly="$store.getters.isLoading"
                                @change="refreshGenerateSettings()"
                            >
                                <v-radio label="常規課堂" value="regular"></v-radio>
                                <v-radio label="單一課堂 (補堂)" value="single"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <template v-if="lessonType === 'regular'">
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <label class="input-label">常規課堂生成週期</label>
                                <v-radio-group 
                                    name="regular_period"
                                    v-model="formData.regular_period"
                                    row
                                    :readonly="$store.getters.isLoading"
                                    @change="generateTimetablePreview()"
                                >
                                    <v-radio label="每週" value="weekly"></v-radio>
                                    <v-radio label="雙週" value="bi-weekly"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <FormDatePicker
                                    label="課程開始日"
                                    :dateValue.sync="formData.regular_start_date"
                                    :required="this.lessonType === 'regular'"
                                    dense
                                    disablePast
                                    @changed="() => {
                                        formData.regular_end_date = '';
                                        generateTimetablePreview();
                                    }"
                                    disabledActionButtons
                                ></FormDatePicker>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3">
                                <FormDatePicker
                                    label="課程結束日"
                                    :dateValue.sync="formData.regular_end_date"
                                    :required="this.lessonType === 'regular'"
                                    dense
                                    disablePast
                                    @changed="generateTimetablePreview()"
                                    :maxDate="regularEndDateMax"
                                    :minDate="formData.regular_start_date"
                                    :disabled="!$validate.DataValid(formData.regular_start_date)"
                                    disabledActionButtons
                                ></FormDatePicker>
                            </v-col>
                            <v-col cols="12">
                                <label class="input-label">課堂週期</label>
                                <v-row class="ma-0">
                                    <template v-for="item in weekdayOptions">
                                        <v-checkbox
                                            :key="item.value"
                                            v-model="formData.regular_weekdays"
                                            :label="item.text"
                                            color="primary"
                                            :value="item.value"
                                            hide-details
                                            class="mr-12"
                                            @click="generateTimetablePreview()"
                                        ></v-checkbox>
                                    </template>
                                </v-row>
                                <div class="v-text-field__details mt-2" v-if="isFormSubmitClicked && this.lessonType === 'regular' && !$validate.DataValid(formData.regular_weekdays)">
                                    <div class="v-messages theme--light error--text" role="alert">
                                        <div class="v-messages__wrapper">
                                            <div class="v-messages__message">請選擇課堂週期</div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </template>
                        <v-col cols="12" sm="6" md="4" lg="3" v-else-if="lessonType === 'single'">
                            <FormDatePicker
                                label="單一課堂(補堂)日期"
                                :dateValue.sync="formData.single_date"
                                :required="this.lessonType === 'single'"
                                dense
                                disablePast
                                disabledActionButtons
                                @changed="generateTimetablePreview()"
                            ></FormDatePicker>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col cols="12" sm="8" md="6" lg="4" v-if="lessonType === 'regular'">
                            <label class="input-label">時間</label>
                            <template  v-if="sortedSelectedWeekdays.length > 0">
                                <div class="d-flex justify-space-between" v-for="day in sortedSelectedWeekdays">
                                    <span class="body-2 colorBlack--text text-no-wrap mt-3 mr-5">星期{{ $formatter.formatWeekday(day) }}：</span>
                                    <FormSelect
                                        :fieldValue.sync="formData.start_times[day]"
                                        required
                                        :options="startTimeOptions"
                                        dense
                                        @changed="formData.end_times[day] = ''; generateTimetablePreview();"
                                    ></FormSelect>
                                    <span class="body-2 colorBlack--text mt-3 mx-5">至</span>
                                    <FormSelect
                                            :fieldValue.sync="formData.end_times[day]"
                                            required
                                            :options="$common.getEndTimeOptions(formData.start_times[day])"
                                            dense
                                            :readonly="!$validate.DataValid(formData.start_times[day])"
                                            @changed="generateTimetablePreview()"
                                    ></FormSelect>
                                </div>
                            </template>
                            <span class="body-2" v-else>未有課堂週期</span>
                        </v-col>

                        <v-col cols="12" sm="6" md="4" lg="3" v-else-if="lessonType === 'single'">
                            <label class="input-label">時間</label>
                            <div class="d-flex justify-space-between">
                                <FormSelect
                                    :fieldValue.sync="formData.start_time"
                                    required
                                    :options="startTimeOptions"
                                    dense
                                    @changed="formData.end_time = ''; generateTimetablePreview();"
                                ></FormSelect>
                                <span class="body-2 colorBlack--text mt-3 mx-5">至</span>
                                <FormSelect
                                        :fieldValue.sync="formData.end_time"
                                        required
                                        :options="$common.getEndTimeOptions(formData.start_time)"
                                        dense
                                        :readonly="!$validate.DataValid(formData.start_time)"
                                        @changed="generateTimetablePreview()"
                                ></FormSelect>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>

        <Datatable
            class="mt-4"
            tableName="課堂日程預覽"
            tableSmallText="您可以於提交後更改系統自動建立的班別課堂時間"
            tableEmptyText="沒有日程"
            :tableData="scheduleTableData"
            :tableHeaders="scheduleTableHeaders"
            disableFooter
            disablePagination
        ></Datatable>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/formField/FormInput.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import CourseColorPicker from '@/components/CourseColorPicker.vue';
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'CreateClass',
    components: {
        FormInput,
        FormSelect,
        FormDatePicker,
        CourseColorPicker,
        Datatable
    },
    computed: {
        selectedCourseData() {
            if (this.$validate.DataValid(this.formData.course_id) && this.$validate.DataValid(this.allCourseData)) {
                const target = this.allCourseData.find(el => el.id === this.formData.course_id);
                if (target) {
                    return target;
                }
            }

            return null;
        },
        regularEndDateMax() {
            if (this.$validate.DataValid(this.formData.regular_start_date)) {
                const maxDate = this.$formatter.convertStrToDate(this.formData.regular_start_date);
                maxDate.setFullYear(maxDate.getFullYear() + 1);
                return this.$formatter.formatDate(maxDate);
            }
           
            return '';
        },
        sortedSelectedWeekdays() {
            return this.formData.regular_weekdays.sort((a, b) => a - b);
        }
    },
    data: () => ({
        isFormSubmitClicked: false,
        allCourseData: null,

        lessonType: 'regular', // 'regular' | 'single'
        formData: {
            course_id: '',
            course_code: '',
            course_name: '',
            tutor_id: '',
            classroom_id: '',
            color: '',
            salary_method: 'by_lesson',
            max_student: 1,
            price: '',
            start_time: '',
            end_time: '',
            start_times: ['', '', '', '', '', '', ''],
            end_times: ['', '', '', '', '', '', ''],
            // for regular
            regular_period: 'weekly', //  'weekly' | 'bi-weekly'
            regular_weekdays: [],
            regular_start_date: '',
            regular_end_date: '',
            // for single date
            single_date: '',
        },
        courseOptions: [],
        tutorOptions: [],
        classroomOptions: [],
        scheduleTableData: [],
        

        // ---- static
        salaryMethodOptions: [
            { value: 'by_lesson', text: '按堂' },
            { value: 'by_student_num', text: '按人數' },
        ],
        weekdayOptions: [
            { value: 0, text: '星期日' },
            { value: 1, text: '星期一' },
            { value: 2, text: '星期二' },
            { value: 3, text: '星期三' },
            { value: 4, text: '星期四' },
            { value: 5, text: '星期五' },
            { value: 6, text: '星期六' },
        ],
        scheduleTableHeaders: [
            { value: 'course_name', text: '課程', width: 220 },
            { value: 'weekday', text: '星期', width: 220 },
            { value: 'full_date', text: '日期', width: 220 },
            { value: 'timeslot', text: '時間', width: 220 },
            { value: 'grade', text: '年級' },
        ],
        startTimeOptions: [],
        maxStudentOptions: [],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog']),
        generateTimetablePreview() {
            this.scheduleTableData = [];

            let gradeText = '';
            if (this.$validate.DataValid(this.selectedCourseData) && this.$validate.DataValid(this.$grade[this.selectedCourseData.grade])) {
                gradeText = this.$grade[this.selectedCourseData.grade];
            }

            if (this.lessonType === 'regular' 
                && this.$validate.DataValid(this.formData.regular_weekdays)
                && this.$validate.DataValid(this.formData.regular_start_date)
                && this.$validate.DataValid(this.formData.regular_end_date)) {
                const startDate = this.$formatter.convertStrToDate(this.formData.regular_start_date);
                const minDate = this.getMinDate(startDate);
                const maxDate = this.$formatter.convertStrToDate(this.formData.regular_end_date);
                const dates = [];
                const selectedWeekdays = this.sortWeekdays(minDate.getDay());
         
                // Case1: 順住落去攞 (e.g. bi-weekly (thu, tue): ['2023-12-07', '2023-12-12', '2023-12-21', '2023-12-26']) 
                // selectedWeekdays.forEach(day => {
                //     const d = this.$formatter.convertStrToDate(this.formData.regular_start_date);
                //     const tempDate = this.getMinDate(d);

                //     // first date of weekday
                //     if (day < minDate.getDay()) {
                //         tempDate.setDate(minDate.getDate() + (7 - minDate.getDay()) + day); 
                //     } else {
                //         tempDate.setDate(minDate.getDate() + (day - minDate.getDay()));
                //     }
                //     dates.push(this.$formatter.formatDate(tempDate));

                //     while(tempDate.getTime() < maxDate.getTime()) {
                //         if (this.formData.regular_period === 'weekly') {
                //             tempDate.setDate(tempDate.getDate() + 7);
                //         } else if (this.formData.regular_period === 'bi-weekly') {
                //             tempDate.setDate(tempDate.getDate() + 14);
                //         }

                //         if (tempDate.getTime() <= maxDate.getTime()) {
                //             dates.push(this.$formatter.formatDate(tempDate));
                //         }
                //     }
                // });


                // Case2: 跟星期Row攞 (e.g. bi-weekly (thu, tue): ['2023-12-07', '2023-12-19', '2023-12-21']) 
                const d = this.$formatter.convertStrToDate(this.formData.regular_start_date);
                const tempDate = this.getMinDate(d);
                let currentPos = 0;

                while (tempDate.getTime() <= maxDate.getTime()) {
                    if (tempDate.getTime() <= maxDate.getTime()) {
                        dates.push(this.$formatter.formatDate(tempDate));
                    }

                    if (this.formData.regular_period === 'weekly') {
                        if (selectedWeekdays.length > 1) {
                            const currentDay = selectedWeekdays[currentPos];
                            let nextDay = null;
                            if (currentPos < selectedWeekdays.length - 1) {
                                nextDay = selectedWeekdays[currentPos + 1];
                            } else {
                                nextDay = selectedWeekdays[0];
                            }

                            if (nextDay < currentDay) {
                                tempDate.setDate(tempDate.getDate() + (7 - currentDay) + nextDay);
                            } else {
                                tempDate.setDate(tempDate.getDate() + (nextDay - currentDay));
                            }

                            if (currentPos < selectedWeekdays.length - 1) {
                                currentPos++;
                            } else {
                                currentPos = 0;
                            }
                        } else {
                            tempDate.setDate(tempDate.getDate() + 7);
                        }
                    } else if (this.formData.regular_period === 'bi-weekly') {
                        if (selectedWeekdays.length > 1) {
                            const currentDay = selectedWeekdays[currentPos];
                            let nextDay = null;
                            if (currentPos < selectedWeekdays.length - 1) {
                                nextDay = selectedWeekdays[currentPos + 1];
                            } else {
                                nextDay = selectedWeekdays[0];
                            }

                            if (nextDay > currentDay) {
                                tempDate.setDate(tempDate.getDate() + (nextDay - currentDay));
                            } else {
                                tempDate.setDate(tempDate.getDate() + (7 - currentDay) + nextDay + 7);
                            }

                            if (currentPos < selectedWeekdays.length - 1) {
                                currentPos++;
                            } else {
                                currentPos = 0;
                            }
                        } else {
                            tempDate.setDate(tempDate.getDate() + 14);
                        }
                    }
                }

                dates.sort((a,b) => {
                    const aDate = this.$formatter.convertStrToDate(a);
                    const bDate = this.$formatter.convertStrToDate(b);
                    return aDate.getTime() - bDate.getTime();
                })

                dates.forEach(el => {
                    const selectedDate = this.$formatter.convertStrToDate(el);
                    const weekday = selectedDate.getDay();
                    this.scheduleTableData.push({
                        course_name: this.formData.course_name,
                        weekday: weekday,
                        date: el,
                        start_time: this.formData.start_times[weekday],
                        end_time: this.formData.end_times[weekday],
                        grade: gradeText
                    })
                })             
            } else if (this.lessonType === 'single' && this.$validate.DataValid(this.formData.single_date)) {
                const selectedDate = this.$formatter.convertStrToDate(this.formData.single_date);
                this.scheduleTableData.push({
                    course_name: this.formData.course_name,
                    weekday: selectedDate.getDay(),
                    date: this.formData.single_date,
                    start_time: this.formData.start_time,
                    end_time: this.formData.end_time,
                    grade: gradeText,
                })
            }
        },

        refreshGenerateSettings() {
            this.isFormSubmitClicked = false;
            this.formData.regular_weekdays = [];
            this.formData.regular_period = 'weekly';
            this.formData.single_date = '';
            this.formData.start_time = '';
            this.formData.end_time = '';
            this.formData.start_times = ['', '', '', '', '', '', ''];
            this.formData.end_times = ['', '', '', '', '', '', ''];
            this.generateTimetablePreview();
        },

        getMinDate(d) {
            const minDate = d;
            if (this.lessonType === 'regular' && this.$validate.DataValid(this.formData.regular_weekdays)) {
                if (this.formData.regular_weekdays.includes(minDate.getDay())) {
                    return minDate;
                } else {
                    const currentDate = minDate.getDate();
                    minDate.setDate(currentDate + 1);
                    return this.getMinDate(d);
                }
            }

            return this.$formatter.formatDateToDate(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
        },

        sortWeekdays(minWeekday) {
            if (this.$validate.DataValid(this.formData.regular_weekdays)) {
                const weekdays = this.formData.regular_weekdays;
                let closestDay = null;
                for (let i = 0; i < weekdays.length; i++) {
                    if (weekdays[i] === minWeekday) {
                        closestDay = weekdays[i];
                        break;
                    } else if (closestDay !== null) {
                        let diff_min_closest = 0;
                        if (closestDay > minWeekday) {
                            diff_min_closest = closestDay - minWeekday;
                        } else {
                            diff_min_closest = (7 - minWeekday) + closestDay;
                        }

                        let diff_min_current = 0;
                        if (weekdays[i] > minWeekday) {
                            diff_min_current = weekdays[i] - minWeekday;
                        } else {
                            diff_min_current = (7 - minWeekday) + weekdays[i];
                        }

                        if (diff_min_closest > diff_min_current) {
                            closestDay = weekdays[i];
                        }

                        continue;
                    } else {
                        closestDay = weekdays[i];
                        continue;
                    }
                }

                let list = [];
                let temp = closestDay;
                while(temp < 7) {
                    if (this.formData.regular_weekdays.includes(temp)) {
                        list.push(temp);
                    }
                    temp++;                   
                }

                let minTemp = 0;
                while(minTemp < closestDay) {
                    if (this.formData.regular_weekdays.includes(minTemp)) {
                        list.push(minTemp);
                    }
                    minTemp++;
                }

                return list;
            }

            return [];
        },
        
        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }
            this.$store.dispatch('setLoading', true);
            this.isFormSubmitClicked = true;

            const valid = this.$refs['form'].validate();
            if (!valid || this.lessonType === 'regular' && !this.$validate.DataValid(this.formData.regular_weekdays)) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            const selectedSchool = await this.getUserSelectedSchool();

            let payload = {
                course_id: parseInt(this.formData.course_id),
                course_code: (this.formData.course_code).trim(),
                course_name: this.formData.course_name,
                tutor_id: parseInt(this.formData.tutor_id),
                classroom_id: parseInt(this.formData.classroom_id),
                color: this.formData.color,
                salary_method: this.formData.salary_method,
                max_student: parseInt(this.formData.max_student),
                school_id: this.$validate.DataValid(selectedSchool) ? selectedSchool : -1,
                price: parseFloat(this.formData.price),
                grade: this.selectedCourseData.grade,
            };

            if (this.lessonType === 'regular') {
                payload.regular_period = this.formData.regular_period;
                payload.regular_weekdays = this.formData.regular_weekdays.sort((a,b) => a - b);
                payload.start_date = this.formData.regular_start_date;
                payload.end_date = this.formData.regular_end_date;
                payload.weekday_start_time = [];
                payload.weekday_end_time = [];

                payload.regular_weekdays.forEach(weekday => {
                    payload.weekday_start_time.push({ weekday: weekday, time: this.formData.start_times[weekday] });
                    payload.weekday_end_time.push({ weekday: weekday, time: this.formData.end_times[weekday] });
                })

                try {
                    await this.$Fetcher.NewRegularLesson(payload);

                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'Timetable'
                    });
                
                } catch (err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }

            } else if (this.lessonType === 'single') {
                payload.single_date = this.formData.single_date;
                payload.lesson_type = 'single';
                payload.start_time = this.formData.start_time;
                payload.end_time = this.formData.end_time;
          
                try {
                    await this.$Fetcher.NewSingleDateLesson(payload);
                    this.$store.dispatch('toggleAlertMessage', {
                        show: true,
                        message: '新增成功',
                        type: 'success',
                        refresh: false,
                        redirect: 'Timetable'
                    });
                
                } catch (err) {
                    this.$common.error(err);
                    this.setDialogMessage({
                        title: "新增失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                } finally {
                    this.$store.dispatch('setLoading', false);
                }
            }
        },

        // ----- Get Options -----
        async getCourseOptions() {
            try {
                let payload = {
                    join_category_data: true
                };
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const { data } = await this.$Fetcher.GetCourses(payload);
                this.allCourseData = this.$common.duplicateData(data);
                this.courseOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: this.$formatter.generateCourseTitle(el)
                    }
                })
                
            } catch {}
        },

        async getTutorOptions() {
            try {
                let payload = {};
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center = [selectedSchool];
                }

                const { data } = await this.$Fetcher.GetTutors(payload);
                this.tutorOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                })
                
            } catch {}
        },

        async getClassroomOptions() {
            try {
                let payload = {};
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const { data } = await this.$Fetcher.GetClassrooms(payload);
                this.classroomOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                })
                
            } catch {}
        },
    },
    async mounted() {
        this.getCourseOptions();
        this.getTutorOptions();
        this.getClassroomOptions();
    },
    async created() {
        const list = [];
        for(let i = 1; i <= 30; i++) {
            list.push({
                value: i,
                text: `${i}人`
            })
        }
        this.maxStudentOptions = list;
        this.startTimeOptions = this.$common.getStartTimeOptions();
    },
    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else {
            next();
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--checkbox .v-label {
    color:  var(--v-colorBlack-base) !important;
    font-size: .875rem !important;
}

</style>