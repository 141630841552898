<template>
  <v-container fluid>
    <PageHeaderSlot> </PageHeaderSlot>

    <v-form ref="dataForm" @submit.prevent="save()">
      <v-card>
        <v-card-text class="px-2">
          <v-row class="ma-0">
            <v-col cols="12" sm="6" md="4" lg="3">
              <FormFileUploader
                uploadText="上載Logo"
                fileInputKey="upload_logo"
                :files.sync="formData.logo_list"
                acceptFormat="image"
                dispatchUpdateOnChange
                single
              ></FormFileUploader>
            </v-col>

            <v-col cols="12">
              <FormTextarea
                label="收據備註"
                :fieldValue.sync="formData.receipt_remarks"
                dense
                dispatchUpdateOnChange
              ></FormTextarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="d-flex align-center">
        <v-btn type="submit" class="primary mt-4" text depressed :loading="$store.getters.isLoading">儲存</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormFileUploader from '@/components/formField/FormFileUploader.vue'
import FormTextarea from '@/components/formField/FormTextarea.vue'

export default {
  name: 'Setting',
  components: {
    FormFileUploader,
    FormTextarea,
  },
  computed: {},
  data: () => ({
    formData: {
      logo_list: [],
      receipt_remarks: '',
    },
  }),
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getSetting() {
      try {
        const data = await this.$Fetcher.GetSetting()
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.formData[key] = data[key]
          }
        }
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }
      this.$store.dispatch('setLoading', true)

      const valid = this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'required',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      let payload = {
       
      }

      try {
        await this.$Fetcher.UpdateSetting(payload)
        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '儲存成功',
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (err) {
        this.$common.error(err)
        this.setDialogMessage({
          title: '儲存失敗',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },

  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    this.getSetting();
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>
