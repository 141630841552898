<template>
    <v-container fluid>
        <PageHeaderSlot> </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            enableSearch
            searchPlaceholder="搜尋訂單編號、學生姓名、電話"
            @search-clicked="searchKeyword($event)"
            @search-input="tableSearchKey = $event"
            enableClearAll
            @all-search-cleared="clearSearch()"
            @refresh-clicked="refreshData()"
            enableRefresh
            @options-update="onTableOptionsChange"
            enableSearchDate
            datePlaceholder="搜尋訂單建立日期"
            enableExport
            @date-selected="searchOrderDate"
            @export-clicked="exportData()"
            :initSearchKeyword="querySearch"
            enableMultiTypeSearch
            :searchTypeOptions="searchTypeOptions"
            :searchType.sync="tableSearchType"
            @export-doc-clicked="exportInvoice($event, 0)"
            :downloadLoading="downloadLoading"
        ></Datatable>
    </v-container>
</template>

<script>
import Datatable from '@/components/Datatable.vue';
import ExcelMixin from '@/mixins/ExcelMixin.vue';

export default {
    name: 'OrderList',
    mixins: [ExcelMixin],
    components: {
        Datatable,
    },
    computed: {
        query() {
            return this.$route.query;
        },
        querySearch() {
            return this.$route.query.search || '';
        }
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,
        tableSearchKey: '',
        tableSearchType: 'id',
        tableSearchOrderDate: '',

        exportLoading: false,
        downloadLoading: false,
        downloadTimeOut: null,
        invoiceFile: '',

        // --- static data
        tableHeaders: [
            { value: 'order_id', text: '訂單編號' },
            { value: 'order_type', text: '類別' },
            { value: 'month', text: '月份' },
            { value: 'order_items', text: '項目', width: 420 },
            { value: 'qty', text: '數量' },
            { value: 'actionViewPayment', text: '', align: 'end' },
            { value: 'actionExportDoc', text: '', align: 'end', btnTitle: '下載收據' },
        ],
        searchTypeOptions: [
            { value: 'id', text: '訂單編號' },
            { value: 'student_name', text: '學生姓名' },
            { value: 'phone', text: '電話' },
        ],
    }),
    methods: {
        async getPayload() {
            let payload = {
                filter_limit: this.tableLimit,
                filter_page: this.tablePage - 1,
                filter_item: [],
            };

            const selectedSchool = await this.getUserSelectedSchool();
            if (this.$validate.DataValid(selectedSchool)) {
                payload.center_id = selectedSchool;
            }

            if (this.$validate.DataValid(this.tableSearchOrderDate)) {
                payload.date = this.tableSearchOrderDate;
                payload.filter_item.push({ key: 'create_date', value: this.tableSearchOrderDate })
            }

            if (this.$validate.DataValid(this.tableSearchKey)) {
                const keyword = this.tableSearchKey.trim();

                if (this.$validate.DataValid(this.tableSearchType)) {
                    if (this.tableSearchType === 'id') {
                        if (this.$validate.isValidId(keyword)) {
                            const id = keyword.substring(1);
                            payload.filter_item.push({ key: 'id', value: parseInt(id) });
                        } else if (this.$validate.regexNumber(keyword)) {
                            payload.filter_item.push({ key: 'id', value: parseInt(keyword) });
                        }
                    } else {
                        payload.filter_item.push({ key: this.tableSearchType, value: keyword });
                    }
                } else {
                    payload.search = keyword;
                }
            }

            if (payload.filter_item.length < 1) {
                delete payload.filter_item;
            }

            return payload;
        },
        async getOrderData(regenUrl = true) {
            if (regenUrl === true) {
                this.regenerateURL();
            }
            
            this.tableLoading = true;
            try {
                const payload = await this.getPayload();
                const { data, total } = await this.$Fetcher.GetOrders(payload);
                this.tableData = data.map(el => {
                    let items = [];
                    if (el.order_type === 'course') {
                        const group = [];
                        el.item_datas.forEach(item => {
                            if (group.length > 0) {
                                const related = group.find(temp => temp[0].course_name === item.course_name && temp[0].course_code === item.course_code);
                                if (related) {
                                    related.push(item)
                                } else {
                                    group.push([item]);
                                }
                            } else {
                                group.push([item]);
                            }
                        })

                        group.forEach(item => {
                           items.push(item[0].course_name);
                        })

                    } else if (el.order_type === 'product') {
                        items = el.item_datas.map(item => item.name);
                    }
                    return {
                        id: el.id,
                        order_id: el.id,
                        order_type: el.order_type,
                        month: el.month,
                        order_items: items.join(', '),
                        qty: el.item_list.reduce((count, item) => count + (this.$validate.DataValid(item.amount) ? item.amount : 1), 0),
                        payment_image: el.payment_image
                    }
                });
              
                this.tableItemTotal = total;
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        searchKeyword(keyword) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = keyword.trim();
            this.getOrderData();
        },
        searchOrderDate(date) {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchOrderDate = date;
            this.getOrderData();
        },
        clearSearch() {
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.tableSearchKey = '';
            this.tableSearchOrderDate = '';
            this.tableSearchId = '';
            this.tableSearchType = this.searchTypeOptions[0].value;
            this.getOrderData();
        },
        refreshData() {
            this.tableLimit = 10;
            this.tablePage = 1;
            this.tableItemTotal = 0;
            this.getOrderData();
        },
        
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getOrderData();
        },

        async exportData() {
            if (this.exportLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.exportLoading = true;

            try {
                const header = {
                    order_id: '訂單編號',
                    order_type: '類別',
                    month: '月份',
                    name: '項目',
                    qty: '數量'
                }

                const payload = await this.getPayload();
                const { data } = await this.$Fetcher.GetOrders(payload);
                const exportData = data.map(el => {
                    let items = [];
                    if (el.order_type === 'course') {
                        const group = [];
                        el.item_datas.forEach(item => {
                            if (group.length > 0) {
                                const related = group.find(temp => temp[0].course_name === item.course_name && temp[0].course_code === item.course_code);
                                if (related) {
                                    related.push(item)
                                } else {
                                    group.push([item]);
                                }
                            } else {
                                group.push([item]);
                            }
                        })

                        group.forEach(item => {
                           items.push(item[0].course_name);
                        })
                    } else if (el.order_type === 'product') {
                        items = el.item_datas.map(item => item.name);
                    }
                    return {
                        order_id: this.$formatter.generateId(el.id),
                        order_type: this.$orderType[el.order_type],
                        month: el.month,
                        name: items.join(', '),
                        qty: el.item_list.reduce((count, item) => count + (this.$validate.DataValid(item.amount) ? item.amount : 1), 0),
                    }
                });

                this.exportExcel(
                    header,
                    exportData,
                    '近期帳單',
                    `訂單紀錄_${this.$formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
                );
            } catch(err) {
                this.$common.error(err);
                this.setDialogMessage({
                    title: "匯出失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.exportLoading = false;
            }
        },

        async exportInvoice(orderId, count = 0) {
            if (this.downloadLoading) {
                return;
            } 

            this.invoiceFile = '';
            this.downloadLoading = true;
            try {
                const file = await this.$Fetcher.ExportOrderInvoice(orderId);
         
                var download = document.createElement('a');
                download.download = file;
                download.href = `${this.$mediaPath}${file}`;
                download.style.display = 'none';
                download.target = '_blank';
                document.body.appendChild(download);
                download.click();
                download.remove();

                if (this.downloadTimeOut !== null) {
                    clearTimeout(this.downloadTimeOut);
                }
                this.downloadLoading = false;
            } catch(err) {
                this.$common.error(err);
                if (count < 5) {
                    this.downloadTimeOut = setTimeout(async () => {
                        this.downloadLoading = false;
                        count++;
                        await this.exportInvoice(orderId, count);
                    }, 100);
                } else {
                    this.setDialogMessage({
                        title: "下載失敗",
                        message: err,
                        isError: true,
                        returnLink: null,
                    });
                    this.setShowDialog(true);
                    this.downloadLoading = false;
                }
            }
        },

        getQuery() {
            if (Object.keys(this.query).length > 0) {
                if (this.$validate.DataValid(this.query.searchType)) {
                    this.tableSearchType = this.query.searchType;
                }

                if (this.$validate.DataValid(this.querySearch)) {
                    this.tableSearchKey = this.querySearch;
                }

                if (this.$validate.DataValid(this.query.orderDate)) {
                    this.tableSearchOrderDate = this.query.orderDate;
                }

                if (this.$validate.DataValid(this.query.page) && this.$validate.regexNumber(this.query.page)) {
                    this.tablePage = parseInt(this.query.page);
                }

                if (this.$validate.DataValid(this.query.limit) && this.$validate.regexNumber(this.query.limit)) {
                    this.tableLimit = parseInt(this.query.limit);
                }
            }
        },

        regenerateURL() {
            const q = {
                searchType: this.tableSearchType,
                search: this.tableSearchKey.trim(),
                orderDate: this.tableSearchOrderDate,
                page: (this.tableLimit !== 10 || this.tablePage !== 1) ? this.tablePage : null,
                limit: (this.tableLimit !== 10 || this.tablePage !== 1) ? this.tableLimit : null,
            };
            const newQuery = this.$common.diffQuery(q, this.query);
            if (newQuery) {
                this.$router.replace({ query: newQuery });
            }
        },

        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: OrderList');
            this.getQuery();
            await this.getOrderData(false);
        }
    },

    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.tableLoading = true;
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },
}
</script>