<!-- Remarks:
    1. 課程會default出咗學生上一個月報過的所有課程，直至學生退學為止
    2. 只可選 報讀新課程 或 購買商品，不可同時付款
 -->

<template>
    <v-container fluid>
        <PageHeaderSlot showBackButton> </PageHeaderSlot>
        <v-row class="ma-0">
            <v-col class="pl-0 pr-0 pr-md-8" cols="12" md="9">
                <v-tabs 
                    class="mb-4" 
                    :class="{'pointer-none': $store.getters.isLoading}"
                    v-model="paymentTypeTab" 
                    color="colorBlack" 
                    :height="40" 
                    active-class="tab-active"
                    hide-slider 
                    @change="onTabChange()"
                >
                    <v-tab>報讀新課程</v-tab>
                    <v-tab>購買商品</v-tab>
                </v-tabs>
              
                <v-tabs-items v-model="paymentTypeTab" class="mt-8">
                    <!-- Course -->
                    <v-tab-item transition="fade-transition">
                        <v-row class="ma-0 mb-5">
                            <v-btn class="primary" text @click.prevent="openAddSearchDialog()">報讀新課程</v-btn>
                        </v-row>

                        <Datatable
                            :isLoading="tableLoading"
                            tableName="課程" 
                            :tableHeaders="courseTableHeaders" 
                            :tableData="courseTableData"
                            disableFooter 
                            disablePagination 
                            enableSelectToggle 
                            :selectedTableRow.sync="courseSelectedRow" 
                            tableEmptyText="沒有課程"
                            @edit-clicked="handleEditOpen($event, 'course')"
                            @delete-clicked="handleDeleteAction"
                        ></Datatable>
                    </v-tab-item>

                    <!-- Product -->
                    <v-tab-item transition="fade-transition">
                        <v-row class="ma-0 mb-5">
                            <v-btn class="primary" text  @click.prevent="openAddSearchDialog">新增商品</v-btn>
                        </v-row>

                        <Datatable 
                            :isLoading="tableLoading"
                            tableName="商品" 
                            :tableHeaders="productTableHeaders" 
                            :tableData="productTableData"
                            disableFooter 
                            disablePagination 
                            enableSelectToggle 
                            :selectedTableRow.sync="productSelectedRow" 
                            tableEmptyText="沒有商品"
                            @edit-clicked="handleEditOpen($event, 'product')"
                            @delete-clicked="handleDeleteAction"
                        ></Datatable>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <!-- 訂單詳情 -->
            <v-col class="px-0 my-4" cols="12" md="3">
                <v-card>
                    <v-card-title class="subtitle-2 order-card-title">訂單詳情</v-card-title>
                    <v-card-text class="px-0 py-4">
                        <v-row class="ma-0 pb-3 px-4 details-row">
                            <span>小計</span>
                            <span>${{$formatter.separator(subtotal)}}</span>
                        </v-row>
                        <v-row class="ma-0 pb-3 px-4 details-row">
                            <span>{{currentTabType === 'product' ? '產品數量' : '課堂數量'}}</span>
                            <span>{{qty}}</span>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row class="ma-0 pb-3 px-4 details-row">
                            <span>支付金額</span>
                            <span style="font-size: 40px;">${{$formatter.separator(totalAmount)}}</span>
                        </v-row>
                        <v-row class="ma-0 pb-3 px-4">
                            <v-col class="pa-0 ma-0" cols="12">
                                <FormSelect
                                    label="支持方式"
                                    :fieldValue.sync="selectedPaymentMethod"
                                    :options="paymentMethodList"
                                    required
                                    dense
                                ></FormSelect>
                            </v-col>
                            <v-col class="pa-0 ma-0" cols="12">
                                <FormSelect
                                    label="付款狀態"
                                    :fieldValue.sync="selectedStatus"
                                    :options="statusOptions"
                                    required
                                    dense
                                ></FormSelect>
                            </v-col>
                            <v-col class="pa-0 mb-4" cols="12">
                                <FormFileUploader
                                    uploadText="上載付款記錄"
                                    fileInputKey="upload_payment"
                                    :files.sync="paymentImages"
                                    acceptFormat="image"
                                    dispatchUpdateOnChange
                                    single
                                ></FormFileUploader>
                            </v-col>
                            <v-col class="pa-0 ma-0" cols="12">
                                <FormInput
                                    label="備註"
                                    placeholder="新增備註"
                                    :fieldValue.sync="remark"
                                    dense
                                ></FormInput>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-btn :loading="$store.getters.isLoading" class="accent my-3 colorBlack--text" text width="100%" height="48px" min-height="48px" @click.prevent="handlePaymentSubmit()">付款</v-btn>

                <v-card>
                    <v-card-text class="pa-4">
                        <v-row class="ma-0 pb-6 details-row">
                            <div>
                                <div class="mb-2">學生名稱</div>
                                <div class="font-weight-regular">{{studentData.student_name}}</div>
                            </div>
                            <v-avatar size="40" v-if="studentAvatar !== ''">
                                <img :src="studentAvatar" :alt="studentData.student_name">
                            </v-avatar>
                        </v-row>
                      
                        <v-row class="ma-0 pb-6 details-row">
                            <div>
                                <div class="mb-2">學生電話</div>
                                <div class="font-weight-regular">{{studentData.phone}}</div>
                            </div>
                            <v-btn class="whatsapp" text :href="`https://wa.me/${studentData.phone}`" target="_blank">
                                <v-icon size="24" color="white">{{ icon.mdiWhatsapp }}</v-icon>
                            </v-btn>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-space-between">
                        <v-btn class="primary" text width="45%" :to="{name: 'StudentDetails', params: {id: studentData.student_id} }">檢視學生資料</v-btn>
                        <v-btn class="primary" text width="45%" :to="{name: 'OrderList', query: { searchType: 'phone', search: studentData.phone }}">近期帳單</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- 報讀新課程 Dialog -->
        <DialogLayout 
            :openDialog="addCourseDialogOpen"
            :maxWidth="910"
            title="報讀新課程"
            hideAction
            enableScroll
            cardContentClass="pa-0"
            @close="addCourseDialogOpen = false"
        >
            <div class="pa-4">
                <v-radio-group 
                    name="addCourseType"
                    v-model="addCourseFilter.type"
                    row
                    hide-details
                    class="ma-0"
                    @change="onCourseTypeChange()"
                    :readonly="isSearching"
                >
                    <v-radio label="報讀課程" value="weekday"></v-radio>
                    <v-radio label="報讀單一課堂 (補堂)" value="singleDate"></v-radio>
                </v-radio-group>

                <v-row class="ma-0 my-4">
                    <v-col cols="12" sm="6">
                        <Searchbar
                            label="搜尋課程"
                            :searchText.sync="addCourseFilter.keyword"
                            searchPlaceholder="搜尋課程名稱／編號"
                            @search-clicked="onCourseFilterChange()"
                            @search-clear="onCourseFilterChange()"
                            :isSearching="isSearching"
                            :searchType.sync="addCourseFilter.keywordType"
                            :searchTypeOptions="searchTypeOptions"
                            enableMultiTypeSearch
                            enabledClear
                        ></Searchbar>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="addCourseFilter.type === 'weekday'">
                        <FormSelect
                            label="星期"
                            :fieldValue.sync="addCourseFilter.weekdays"
                            :options="weekdayOptions"
                            hideDetails
                            :readonly="isSearching"
                            placeholder="選擇日子"
                            isMultiple
                            dense
                            @changed="onCourseFilterChange()"
                        ></FormSelect>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="addCourseFilter.type === 'singleDate'">
                        <FormDatePicker
                            label="課堂日期"
                            :dateValue.sync="addCourseFilter.dateRange"
                            isDateRange
                            @changed="onCourseFilterChange()"
                            hideDetails
                            placeholder="選擇課堂日期"
                            dense
                        ></FormDatePicker>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <FormSelect
                            label="導師"
                            :fieldValue.sync="addCourseFilter.tutor"
                            :options="tutorOptions"
                            hideDetails
                            :readonly="isSearching"
                            placeholder="選擇導師"
                            dense
                            @changed="onCourseFilterChange()"
                        ></FormSelect>
                    </v-col>

                    <v-col cols="12" sm="6">
                        <FormSelect
                            label="年級"
                            :fieldValue.sync="addCourseFilter.grade"
                            :options="$gradeOptions"
                            hideDetails
                            :readonly="isSearching"
                            placeholder="選擇年級"
                            dense
                            @changed="onCourseFilterChange()"
                        ></FormSelect>
                    </v-col>
                </v-row>
            </div>

            <Datatable
                :isLoading="isSearching"
                :tableData="searchTableData"
                :tableHeaders="addCourseTableHeaders"
                :itemTotal="searchTableTotal"
                :page="searchTableOptions.tablePage"
                :pageLimit="searchTableOptions.tableLimit"
                :pageLimitOptions="searchTableOptions.limitOptions"
                @apply-clicked="handleAddNewCourse($event)"
                @options-update="onSearchTableOptionsChange($event)"
                tableEmptyText="沒有課程"
            ></Datatable>
        </DialogLayout>

        <ConfirmDialog
            ref="exceedStudentDialog" 
            title="是否報讀此課程？" 
            :isDeleteDialog="false"
            enableShowRemindCheckbox
        >已到達課室學生上限，是否繼續報讀此課程？</ConfirmDialog>

        <!-- 新增產品 Dialog -->
        <DialogLayout 
            :openDialog="addProductDialogOpen"
            :maxWidth="910"
            title="新增產品"
            hideAction
            enableScroll
            cardContentClass="pa-0"
            @close="addProductDialogOpen = false"
        >
            <div class="pa-4">
                <v-row class="ma-0 my-4">
                    <v-col cols="12" sm="6">
                        <Searchbar
                            label="搜尋產品"
                            :searchText.sync="addProductKeyword"
                            searchPlaceholder="搜尋產品名稱"
                            @search-clicked="onProductFilterChange()"
                            @search-clear="onProductFilterChange()"
                            :isSearching="isSearching"
                            enabledClear
                        ></Searchbar>
                    </v-col>
                </v-row>
            </div>

            <Datatable
                :isLoading="isSearching"
                :tableData="searchTableData"
                :tableHeaders="addProductTableHeaders"
                :itemTotal="searchTableTotal"
                :page="searchTableOptions.tablePage"
                :pageLimit="searchTableOptions.tableLimit"
                :pageLimitOptions="searchTableOptions.limitOptions"
                @apply-clicked="handleAddNewProduct"
                @options-update="onSearchTableOptionsChange"
                tableEmptyText="沒有產品"
            ></Datatable>
        </DialogLayout>

        <!-- 修改項目資料 Dialog -->
        <FormDialog
            ref="editFormDialog"
            formRef="editForm"
            title="修改項目資料"
            :maxWidth="910"
            cardContentClass="pa-0"
            @submit-clicked="handleEditSubmit()"
        >
            <v-row class="ma-0 my-4">
                <v-col cols="12" sm="6">
                    <FormSelect
                        label="折扣類型"
                        :fieldValue.sync="editDiscountType"
                        :options="discountTypeOptions"
                        placeholder="選擇折扣類型"
                        dense
                        @changed="editDiscountValue = ''"
                    ></FormSelect>
                </v-col>

                <v-col cols="12" sm="6">
                    <FormInput
                        type="number"
                        :label="editDiscountLabel"
                        :fieldValue.sync="editDiscountValue"
                        dense
                        :readonly="!$validate.DataValid(editDiscountType)"
                        :prefix="editDiscountType === 'fix_amount' ? '$' : ''"
                        :suffix="editDiscountType === 'percentage' ? '%' : ''"
                    ></FormInput>
                </v-col>

                <v-col cols="12" sm="6">
                    <div class="input-label">價錢</div>
                    <div class="d-flex align-center mt-3">
                        <span :class="{'text-decoration-line-through': $validate.DataValid(editDiscountPrice)}" class="colorBlack--text">${{$formatter.separator(editOriginalPrice || 0)}}</span>
                        <span v-if="$validate.DataValid(editDiscountPrice)" class="primary--text ml-2">${{$formatter.separator(editDiscountPrice)}}</span>
                    </div>
                </v-col>
            </v-row>

            <Datatable
                v-if="currentTabType === 'course'"
                :isLoading="editTableLoading"
                :tableData="editTableData"
                :tableHeaders="editCourseTableHeaders"
                disableFooter
                disablePagination
                enableSelectToggle
                :selectedTableRow.sync="editCourseSelectedRow"
                tableEmptyText="沒有課程"
            ></Datatable>
            <Datatable
                v-else-if="currentTabType === 'product'"
                :isLoading="editTableLoading"
                :tableData="editTableData"
                :tableHeaders="editProductTableHeaders"
                tableEmptyText="沒有產品"
                disableFooter
                disablePagination
            ></Datatable>
        </FormDialog>


        <ConfirmDialog ref="deleteDialog" enableShowRemindCheckbox></ConfirmDialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiWhatsapp } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import FormInput from '@/components/formField/FormInput.vue';
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import DialogLayout from '@/components/layout/DialogLayout.vue';
import FormDialog from '@/components/FormDialog.vue';
import Searchbar from '@/components/Searchbar.vue';
import FormFileUploader from '@/components/formField/FormFileUploader.vue';

export default {
    name: 'PayTuitionPayment',
    components: {
        Datatable,
        ConfirmDialog,
        FormSelect,
        FormInput,
        FormDatePicker,
        DialogLayout,
        FormDialog,
        Searchbar,
        FormFileUploader
    },
    computed: {
        id() {
            const id = parseInt(this.$route.params.id);
            if (!isNaN(id)) {
                return id;
            }

            return null;
        },
        currentTabType() {
            return this.tabValues[this.paymentTypeTab];
        },
        courseTableHeaders() {
            const headers = [
                { value: 'course_name', text: '收費項目', width: 150 },
                { value: 'month', text: '月份', hasYear: true },
                { value: 'dates', text: '日期', width: 300 },
            ];
            if (this.$validate.DataValid(this.courseTableData)) {
                return [
                    ...headers, 
                    { value: 'unit_price', text: '價錢' },
                    { value: 'qty', text: '數量' },
                    { value: 'total', text: '小計' },
                    { value: 'minActionEditAndDelete', text: '', align: 'end' },
                ]
            }

            return [
                ...headers, 
                { value: 'unit_price', text: '' },
                { value: 'qty', text: '' },
                { value: 'total', text: '' },
                { value: 'minActionEditAndDelete', text: '', align: 'end' },
            ]
        },
        productTableHeaders() {
            const headers = [
                { value: 'product_name', text: '收費項目', width: 150 },
            ];
            if (this.$validate.DataValid(this.productTableData)) {
                return [
                    ...headers, 
                    { value: 'unit_price', text: '小計' },
                    { value: 'qty', text: '數量' },
                    { value: 'total', text: '價錢' },
                    { value: 'minActionEditAndDelete', text: '', align: 'end' },
                ]
            }

            return headers;
        },
        studentAvatar() {
            if (this.studentData.gender === 'M') {
                return require('@/assets/images/gender-m.svg');
            } else if (this.studentData.gender === 'F') {
                return require('@/assets/images/gender-f.svg');
            } else {
                return '';
            }
        },
        totalAmount() {
            return this.subtotal;
        },
        addCourseTableHeaders() {
            // const dateCol = { value: '', text: '' };
            // if (this.addCourseFilter.type === 'weekday') {
            //     dateCol.value = 'weekday';
            //     dateCol.text = '星期';
            // } else if (this.addCourseFilter.type === 'singleDate') {
            //     dateCol.value = 'full_date';
            //     dateCol.text = '日期';
            // }

            return [
                { value: 'course_name', text: '收費項目' },
                { value: 'full_date', text: '日期', hasWeekday: this.addCourseFilter.type === 'weekday' },
                { value: 'timeslot', text: '時間' },
                { value: 'grade', text: '年級' },
                { value: 'tutor', text: '導師' },
                { value: 'current_per_max_student_num', text: '人數' },
                { value: 'actionSelectCourse', text: '', align: 'end'},
            ]
        },
        editDiscountLabel() {
            if (this.$validate.DataValid(this.editDiscountType) 
                && this.$validate.regexFloating(this.editDiscountValue)
                && this.$validate.regexFloating(this.editOriginalPrice))  {
                    if (this.editDiscountType === 'percentage') {
                        const off = 100 - parseFloat(this.editDiscountValue);

                        return `折扣（${off % 10 === 0 ? off * 0.1 : off }折）`;

                    } else if (this.editDiscountType === 'fix_amount') {
                        return `折扣（-$ ${this.$formatter.formatMoney(parseFloat(this.editOriginalPrice) - parseFloat(this.editDiscountPrice || '0'))}）`;
                    }
            }

            return '折扣';
        },
        editDiscountPrice() {
            if (this.$validate.DataValid(this.editDiscountType) 
                && this.$validate.regexFloating(this.editDiscountValue)
                && this.$validate.regexFloating(this.editOriginalPrice)) {
                    let discount = null;
                    if (this.editDiscountType === 'percentage') {
                        discount = this.$formatter.formatMoney(parseFloat(this.editOriginalPrice) * (1 - (parseFloat(this.editDiscountValue) * 0.01)));

                    } else if (this.editDiscountType === 'fix_amount') {
                        discount = this.$formatter.formatMoney(parseFloat(this.editOriginalPrice) - parseFloat(this.editDiscountValue));
                    }

                    if (discount !== null) {
                        if (discount < 0) {
                            return '0';
                        } else {
                            return discount.toString();
                        }
                    }
            }

            return '';
        },
    },
    data: () => ({
        tableLoading: false,
        tutorOptions: [],
        paymentTypeTab: 0,
        subtotal: 0,
        qty: 0,

        studentData: {
            student_id: -1,
            student_name: '',
            gender: '',
            phone: '',
            school_id: -1,
            grade: '',
        },
        remark: '',
        selectedPaymentMethod: 'cash',
        paymentImages: [],
        selectedStatus: 'paid',

        searchTableOptions: {
            limitOptions: [5, 10, 20],
            tableLimit: 5,
            tablePage: 1,
        },
        searchTableTotal: 0,
        searchTableData: [],
        isSearching: false,


        // edit common
        editTableLoading: false,
        editTableData: [],
        editDiscountType: '', // 'percentage' | 'fix_amount'
        editDiscountValue: '',
        editOriginalPrice: '',
        // edit course
        editCourseSelectedRow: [],
        
        // ===== Course related
        courseTableData: [],
        courseSelectedRow: [],
        appliedLessonIds: [],

        // search course
        addCourseFilter: {
            type: 'weekday', // 'weekday' | 'singleDate'
            keyword: '',
            keywordType: 'course_name',
            weekdays: [],
            dateRange: [],
            tutor: '', // tutor id
            grade: '',
        },
        addCourseDialogOpen: false,
      

        // ===== Product related
        productTableData: [],
        productSelectedRow: [],
        // search product
        addProductDialogOpen: false,
        addProductKeyword: '',


        // ===== static data
        tabValues: ['course', 'product'],
        paymentMethodList: [
            { value: 'cash', text: '現金' },
            { value: 'fps', text: 'FPS' },
            { value: 'bank_transfer', text: '銀行轉帳' },
            { value: 'payme', text: 'PayMe' }
        ],
        weekdayOptions: [
            { value: '0', text: '日' },
            { value: '1', text: '一' },
            { value: '2', text: '二' },
            { value: '3', text: '三' },
            { value: '4', text: '四' },
            { value: '5', text: '五' },
            { value: '6', text: '六' }
        ],
        discountTypeOptions: [
            { value: 'percentage', text: '百分比' },
            { value: 'fix_amount', text: '價格' }
        ],
        editCourseTableHeaders: [
            { value: 'full_date', text: '日期', width: 145 }, // need 'date'
            { value: 'weekday', text: '星期', width: 145 },
            { value: 'timeslot', text: '時間' },
        ],
        addProductTableHeaders: [
            { value: 'product_name', text: '收費項目', width: 400 },
            { value: 'inventory', text: '庫存' },
            { value: 'qtySetter', text: '數量', align: 'center', width: 140 },
            { value: 'actionSelectProduct', text: '', align: 'end' },
        ],
        editProductTableHeaders: [
            { value: 'product_name', text: '收費項目', width: 400 },
            { value: 'inventory', text: '庫存' },
            { value: 'qtySetter', text: '數量', align: 'center', width: 140 },
        ],
        icon: {
            mdiWhatsapp
        },
        searchTypeOptions: [
            { value: 'course_name', text: '課程名稱' },
            { value: 'course_code', text: '課程編號' },
        ],
        statusOptions: [
            { value: 'paid', text: '已繳交' },
            { value: 'pending', text: '未繳交' },
            { value: 'processing', text: '待審核' },
        ],
        // ===== End of static data
    }),
    watch: {
        courseSelectedRow: {
            handler(val) {
                if (this.currentTabType === 'course') {
                    const selectedIds = val.map(el => el.id);
                    const selectedRows = this.courseTableData.filter(el => selectedIds.includes(el.id));
                    this.subtotal = selectedRows.reduce((count, item) => count + (this.getUnitPrice(item) * item.qty ), 0);
                    this.qty = selectedRows.reduce((count, item) => count + item.qty, 0);
                }
            },
            deep: true,
            immediate: true
        },
        courseTableData: {
            handler(val) {
                if (this.currentTabType === 'course') {
                    const selectedIds = this.courseSelectedRow.map(el => el.id);
                    const selectedRows = val.filter(el => selectedIds.includes(el.id));
                    this.subtotal = selectedRows.reduce((count, item) => count + (this.getUnitPrice(item) * item.qty ), 0);
                    this.qty = selectedRows.reduce((count, item) => count + item.qty, 0);
                }
            },
            deep: true,
            immediate: true
        },
        productSelectedRow: {
            handler(val) {
                if (this.currentTabType === 'product') {
                    const selectedIds = val.map(el => el.id);
                    const selectedRows = this.productTableData.filter(el => selectedIds.includes(el.id));
                    this.subtotal = selectedRows.reduce((count, item) => count + (this.getUnitPrice(item) * item.qty ), 0);
                    this.qty = selectedRows.reduce((count, item) => count + item.qty, 0);
                }
            },
            deep: true,
            immediate: true
        },
        productTableData: {
            handler(val) {
                if (this.currentTabType === 'product') {
                    const selectedIds = this.productSelectedRow.map(el => el.id);
                    const selectedRows = val.filter(el => selectedIds.includes(el.id));
                    this.subtotal = selectedRows.reduce((count, item) => count + (this.getUnitPrice(item) * item.qty ), 0);
                    this.qty = selectedRows.reduce((count, item) => count + item.qty, 0);
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog']),
        async getPayTuitionData() {
            try {
                const data = await this.$Fetcher.GetPayTuitionById(this.id);
                for(const key in this.studentData) {
                    if (this.$validate.DataValid(data[key])) {
                        this.studentData[key] = data[key];
                    }
                }

               await this.generateNextMonthInvoice();
            } catch(err) {
                this.$common.error(err);
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'PayTuitionList'}
                });
                this.setShowDialog(true);
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async generateNextMonthInvoice() {
            try {
                await this.$Fetcher.GenNextMonthOrder(this.studentData.student_id);
            } catch(err) {
                this.$common.error(err);
            } finally {
                await this.getTutorOptions();
            }
        },

        async getStudentExistedLessons() {
            try {
                const { data } = await this.$Fetcher.GetLessons({filter_item: [{ key: 'student_id', value: this.studentData.student_id }]});
                // const temp = [];
                // data.forEach(el => {
                //     if (el.student_ids.includes(this.studentData.student_id)) {
                //         temp.push(el.id);
                //     }
                // })
                this.appliedLessonIds = data.map(el => el.id);
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },

        async getTutorOptions() {
            try {
                let payload = {
                    center: [this.studentData.school_id]
                };

                const { data } = await this.$Fetcher.GetTutors(payload);
                this.tutorOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                });
                this.tutorOptions.unshift({ value: -1, text: '所有導師' });
                
            } catch {

            } finally {
                this.getStudentExistedLessons();
            }
        },

        getUnitPrice(item) {
            return this.$validate.DataValid(item.discount_price) ? item.discount_price : item.unit_price;
        },

        onTabChange() {
            if (!this.$store.getters.isLoading) {
                if (this.currentTabType === 'product') {
                    this.courseSelectedRow = [];
                    this.productSelectedRow = this.productTableData.map(el => { return { id: el.id }} );
                } else {
                    this.productSelectedRow = [];
                    this.courseSelectedRow = this.courseTableData.map(el => { return { id: el.id }} );
                }
            }
        },

        openAddSearchDialog() {
            if (this.$store.getters.isLoading) {
                return;
            }
            // -- reset table
            this.searchTableOptions.tablePage = 1;
            this.searchTableTotal = 0;
            this.searchTableData = [];

            // -- reset course search
            this.addCourseFilter.type = 'weekday';
            this.clearAddCourseFilter();

            // -- reset product search
            this.addProductKeyword = '';

            // -- open dialog
            if (this.currentTabType === 'course') {
                this.addCourseDialogOpen = true;
                this.addProductDialogOpen = false;
                this.searchCourses();
            } else if (this.currentTabType === 'product') {
                this.addProductDialogOpen = true;
                this.addCourseDialogOpen = false;
                this.searchProducts();
            } else {
                this.addProductDialogOpen = false;
                this.addCourseDialogOpen = false;
            }
        },

        onSearchTableOptionsChange(options) {
            if (options.itemsPerPage !== this.searchTableOptions.tableLimit) {
                this.searchTableOptions.tablePage = 1;
            } else {
                this.searchTableOptions.tablePage = options.page;
            }

            this.searchTableOptions.tableLimit = options.itemsPerPage;

            if (this.currentTabType === 'course') {
                this.searchCourses();
            } else if (this.currentTabType === 'product') {
                this.searchProducts();
            }
        },

        async handleDeleteAction(delete_id) {
            const confirm = await this.$refs.deleteDialog.show();

            if (confirm) {
                if (this.currentTabType === 'course') {
                    const targetCoursePos = this.courseTableData.findIndex(el => el.id === delete_id);
                    if (targetCoursePos > -1) {
                        this.courseTableData.splice(targetCoursePos, 1);
                    }

                    const targetSelectedCoursePos = this.courseSelectedRow.findIndex(el => el.id === delete_id);
                    if (targetSelectedCoursePos > -1) {
                        this.courseSelectedRow.splice(targetSelectedCoursePos, 1);
                    }
                } else if (this.currentTabType === 'product') {
                    const targetProductPos = this.productTableData.findIndex(el => el.id === delete_id);
                    if (targetProductPos > -1) {
                        this.productTableData.splice(targetProductPos, 1);
                    }

                    const targetSelectedProductPos = this.productSelectedRow.findIndex(el => el.id === delete_id);
                    if (targetSelectedProductPos > -1) {
                        this.productSelectedRow.splice(targetSelectedProductPos, 1);
                    }
                }
            }
        },

        // ========= Course Related =========
        clearAddCourseFilter() {
            this.addCourseFilter.weekdays = [];
            this.addCourseFilter.dateRange = [];
            this.addCourseFilter.keyword = '';
            this.addCourseFilter.tutor = '';
            this.addCourseFilter.grade = this.studentData.grade;
            this.searchTableOptions.tablePage = 1;
            this.searchTableTotal = 0;
            this.searchTableData = [];
        },

        onCourseTypeChange() {
            this.clearAddCourseFilter();
            this.searchCourses();
        },

        onCourseFilterChange() {
            this.searchTableOptions.tablePage = 1;
            this.searchTableTotal = 0;
            this.searchCourses();
        },

        async searchCourses() {
            this.isSearching = true;
            this.searchTableData = [];

            let payload = {
                filter_page: this.searchTableOptions.tablePage - 1,
                filter_limit: this.searchTableOptions.tableLimit,
                center_id: this.studentData.school_id,
                join_tutor_data: true,
                filter_item: [],
            };

            const current_month = new Date();
            current_month.setDate(1);
            payload.filter_item.push({ key: 'start_date', value: this.$formatter.formatDate(current_month)});
        
            if (this.$validate.DataValid(this.addCourseFilter.tutor) && this.addCourseFilter.tutor > -1) {
                payload.filter_item.push({ key: 'staff_id', value: this.addCourseFilter.tutor });
            }
  
            if (this.$validate.DataValid(this.addCourseFilter.keywordType) && this.$validate.DataValid(this.addCourseFilter.keyword)) {
                payload.filter_item.push({ key: this.addCourseFilter.keywordType, value: this.addCourseFilter.keyword });
            }
            if (this.$validate.DataValid(this.addCourseFilter.grade)) {
                payload.filter_item.push({ key: 'grade', value: this.addCourseFilter.grade });
            }

            if (this.addCourseFilter.type === 'weekday') {
                payload.filter_item.push({ key: 'lesson_type', value: 'regular' });
                if (this.$validate.DataValid(this.addCourseFilter.weekdays)) {
                    const weekdays = this.addCourseFilter.weekdays.map(el => parseInt(el));
                    payload.filter_item.push({ key: 'weekdays', value: weekdays });
                }
            } else if (this.addCourseFilter.type === 'singleDate') {
                payload.filter_item.push({ key: 'lesson_type', value: 'single' });
                if (this.$validate.DataValid(this.addCourseFilter.dateRange)) {
                    if (this.addCourseFilter.dateRange[0]) {
                        payload.filter_item.push({ key: 'start_date', value: this.addCourseFilter.dateRange[0] });
                    }
                    if (this.addCourseFilter.dateRange[1]) {
                        payload.filter_item.push({ key: 'end_date', value: this.addCourseFilter.dateRange[1] });
                    }
                }
            }

          
            try {
                const { data, total } = await this.$Fetcher.GetLessons(payload);
                this.searchTableTotal = total;
                this.searchTableData = data.map(el => {
                    let selected = false;
                    if (this.$validate.DataValid(this.courseTableData)) {
                        for (let i = 0; i < this.courseTableData.length; i++) {
                            if (this.$validate.DataValid(this.courseTableData[i].selected_lesson_id_list) &&
                                this.courseTableData[i].selected_lesson_id_list.includes(el.id)) {
                                    selected = true;
                                    break;
                            }
                        }
                    }

                    return {
                        id: el.id, // lesson id
                        lesson_type: el.lesson_type,
                        course_id: el.course_id,
                        regular_id: el.regular_id,
                        course_name: el.course_name,
                        course_code: el.course_code,
                        date: el.date,
                        weekday: this.$formatter.getDay(el.date),
                        start_time: el.start_time,
                        end_time: el.end_time,
                        grade: el.grade,
                        tutor: el.tutor_data.name,
                        current_student_num: el.current_student_num,
                        max_student: el.max_student,
                        unit_price: el.price,
                        selected: selected,
                        applied: this.$validate.DataValid(this.appliedLessonIds) && this.appliedLessonIds.includes(el.id)
                    }
                });
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.isSearching = false;
            }
        },

        async handleAddNewCourse(lesson_id) {
            if (this.appliedLessonIds.includes(lesson_id)) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '已報讀此課堂，不可重覆報讀',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }
            const targetLesson = this.searchTableData.find(el => el.id === lesson_id);
            this.$common.logData(targetLesson, '報讀新課程');
            if (this.$validate.DataValid(targetLesson)) {
                if (targetLesson.current_student_num >= targetLesson.max_student) {
                    const confirm = await this.$refs.exceedStudentDialog.show();
                    if (!confirm) {
                        return;
                    }
                }

                if (targetLesson.lesson_type === 'regular') {
                    this.tableLoading = true;

                    try {
                        // --- Get related lesson
                        const targetYear = targetLesson.date.substring(0,4);
                        const targetMonth = targetLesson.date.substring(5,7);
                        const lastDateOfMonth = new Date(parseInt(targetYear), parseInt(targetMonth), 0);

                        let payload = {
                            center_id: this.studentData.school_id,
                            filter_item: [
                                { key: 'course_code', value: targetLesson.course_code },
                                { key: 'course_id', value: targetLesson.course_id },
                                { key: 'lesson_type', value: 'regular' },
                                { key: 'start_date', value: `${targetYear}-${targetMonth}-01` },
                                { key: 'end_date', value: `${targetYear}-${targetMonth}-${this.$formatter.pad(lastDateOfMonth.getDate())}` }
                            ]
                        };
                        
                        const { data } = await this.$Fetcher.GetLessons(payload);
                        const relatedLesson = data.filter(el => !this.appliedLessonIds.includes(el.id));
                        this.$common.logData(relatedLesson, '相關課堂');

                        //-- find if related lesson added
                        let addNewCourse = false;
                        if (this.$validate.DataValid(this.courseTableData)) {
                            const relatedLessonIds = relatedLesson.map(el => el.id);
                            let courseAdded = null;
                            for (let i = 0; i < this.courseTableData.length; i++) {
                               const rowRelatedLesson = this.courseTableData[i].selected_lesson_id_list;
                               for (let k = 0; k < relatedLessonIds.length; k++) {
                                    if (rowRelatedLesson.includes(relatedLessonIds[k])) {
                                        courseAdded = this.courseTableData[i];
                                        break;
                                    }
                               }
                            }

                            if (courseAdded !== null) {  // 如果相關課程已added，將lesson加至該課程的selected_lesson_id_list中
                                courseAdded.selected_lesson_id_list.push(targetLesson.id);
                                courseAdded.dates.push(targetLesson.date);
                                courseAdded.dates.sort((a,b) => {
                                    const aDate = this.$formatter.convertStrToDate(a);
                                    const bDate = this.$formatter.convertStrToDate(b);
                                    return aDate.getTime() < bDate.getTime();
                                })
                                courseAdded.qty += 1;
                                courseAdded.total = this.getUnitPrice(courseAdded) * courseAdded.qty;
                            } else { // 否則，加新課程，並把所有相關lesson加至該課程的selected_lesson_id_list中（包括target lesson）
                                addNewCourse = true;
                            }
                        } else {
                            addNewCourse = true;
                        }

                        if (addNewCourse === true) {
                            const newCourse = this.parseCourseTableData(targetLesson, relatedLesson);
                            this.courseTableData.push(newCourse);
                            this.courseSelectedRow.push({ id: newCourse.id });
                        }
                    } catch(err) {
                        this.$common.error(err);
                    } finally {
                        this.tableLoading = false;
                    }
                  
                } else {
                    let addNewCourse = false;
                    if (this.$validate.DataValid(this.courseTableData)) {
                        const existed = this.courseTableData.find(el => el.id === targetLesson.id);
                        if (!existed) {
                            addNewCourse = true;
                        }
                    } else {
                        addNewCourse = true;
                    }

                    if (addNewCourse === true) {
                        const formattedLessonData = {
                            id: targetLesson.id,
                            date: targetLesson.date,
                            current_student_num: targetLesson.current_student_num,
                            max_student: targetLesson.max_student,
                            course_name: targetLesson.course_name,
                            course_id: targetLesson.course_id,
                            lesson_type: targetLesson.lesson_type,
                            start_time: targetLesson.start_time,
                            end_time: targetLesson.end_time,
                        };

                        const newCourse = this.parseCourseTableData(targetLesson, [formattedLessonData], true);
                        this.courseTableData.push(newCourse);
                        this.courseSelectedRow.push({ id: newCourse.id });
                    }
                }
            }


            this.addCourseDialogOpen = false;
        },

        parseCourseTableData(item, lessonData = [], single = false) {
            // 用其中一個lesson id 作為row id
            // lesson_data_list 內包含該lesson 個個月嘅所有堂(share 同一個regular_id if lesson_type = "regular" & weekday)

            let valid_lessons = JSON.parse(JSON.stringify(lessonData));
            if (!single) {
                valid_lessons = lessonData.filter(el => {
                    const date = this.$formatter.convertStrToDate(el.date);
                    const today = new Date();
                    if (date.getFullYear() < today.getFullYear()) {
                        return false;
                    } else if (date.getFullYear() === today.getFullYear()) {
                        if (date.getMonth() < today.getMonth()) {
                            return false;
                        } else if (date.getMonth() === today.getMonth()) {
                            if (date.getDate() < today.getDate()) {
                                return false;
                            }
                        }
                    }

                    return true;
                });
            }

            return {
                id: item.id, // lesson id
                course_name: item.course_name,
                lesson_type: item.lesson_type,
                lesson_data_list: lessonData,
                selected_lesson_id_list: valid_lessons.map(el => el.id),
                year: item.date.substring(0,4),
                month: item.date.substring(5,7),
                dates: valid_lessons.map(el => el.date),
                unit_price: item.unit_price,
                qty: valid_lessons.length,
                total: this.getUnitPrice(item) * valid_lessons.length,
            }
        },

        // ========= Product Related =========
        onProductFilterChange() {
            this.searchTableOptions.tablePage = 1;
            this.searchTableTotal = 0;
            this.searchProducts();
        },

        async searchProducts() {
            this.isSearching = true;
            this.searchTableData = [];

            let payload = {
                filter_page: this.searchTableOptions.tablePage - 1,
                filter_limit: this.searchTableOptions.tableLimit,
                center_id: this.studentData.school_id,
                filter_item: [],
            };

            if (this.$validate.DataValid(this.addProductKeyword)) {
                payload.filter_item.push({ key: 'name', value: this.addProductKeyword });
            }

            if (payload.filter_item.length < 1) {
                delete payload['filter_item'];
            }

            try {
                const { data, total } = await this.$Fetcher.GetProducts(payload);
                this.searchTableTotal = total;
                this.searchTableData = data.map(el => {
                    const selected = this.productTableData.find(item => item.id === el.id);
                    let qty = 1;
                    if (selected) {
                        qty = selected.qty;
                    }
                    return {
                        id: el.id,
                        product_name: el.name,
                        inventory: el.inventory,
                        qty: qty,
                        unit_price: el.price
                    }
                })
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.isSearching = false;
            }
        },

        handleAddNewProduct(id) {
            const targetProduct = this.searchTableData.find(el => el.id === id);
            if (targetProduct) {
                const existed = this.productTableData.find(el => el.id === id);
                if (existed) {
                    existed.qty = targetProduct.qty;
                    existed.total = this.getUnitPrice(existed) * existed.qty;
                } else {
                    this.productTableData.push(this.parseProductTableData(targetProduct));
                    this.productSelectedRow.push({ id: targetProduct.id });
                }
            }
           
            this.addProductDialogOpen = false;
        },

        parseProductTableData(item) {
            return {
                id: item.id,
                product_name: item.product_name,
                qty: item.qty,
                inventory: item.inventory,
                unit_price: item.unit_price,
                total: this.getUnitPrice(item) * item.qty,
                discount_type: '',
                discount_value: null,
                discount_price: null,
            }
        },

        // ========= 修改項目資料 =========
        async handleEditOpen(id, type) {
            if (this.$store.getters.isLoading) {
                return;
            }

            // reset data
            this.editDiscountType = '';
            this.editDiscountValue = '';
            this.editOriginalPrice = '';
            this.editTableData = [];
            this.editCourseSelectedRow = [];
            this.$refs['editFormDialog'].setEditId(id);
            await this.$refs['editFormDialog'].$refs['editForm'].reset();
         
            this.editTableLoading = true;

            let target = null;
            if (type === 'course') {
                target = this.courseTableData.find(el => el.id === id);
                if (target) {
                    this.$common.logData(target, '修改項目資料 (課程)');

                    this.editTableData = target.lesson_data_list.map(lessonData => {
                        return {
                            id: lessonData.id,
                            date: lessonData.date,
                            weekday: this.$formatter.getDay(lessonData.date),
                            start_time: lessonData.start_time,
                            end_time: lessonData.end_time,
                        }
                    })

                    this.editCourseSelectedRow = this.editTableData.filter(el => target.selected_lesson_id_list.includes(el.id));
                }
            } else if (type === 'product') {
                // -- Edit product item
                target = this.productTableData.find(el => el.id === id);
                if (target) {
                    this.$common.logData(target, '修改項目資料 (商品)');

                    // -- edit product table data
                    this.editTableData = [{
                        id: target.id,
                        product_name: target.product_name,
                        qty: target.qty,
                        inventory: target.inventory
                    }];
                }
            }

            // set price details
            if (target) {
                if (this.$validate.regexFloating(target.unit_price)) {
                    this.editOriginalPrice = (target.unit_price).toString();
                }

                if (this.$validate.regexFloating(target.discount_value)) {
                    this.editDiscountValue = (target.discount_value).toString();
                }

                if (this.$validate.DataValid(target.discount_type)) {
                    this.editDiscountType = target.discount_type;
                }
            }

            this.editTableLoading = false;
            this.$refs['editFormDialog'].show();
        },

        handleEditSubmit() {
            const editId = this.$refs['editFormDialog'].getEditId();
            if (this.$validate.DataValid(editId)) {
                let target = null;
                if (this.currentTabType === 'course') {
                    target = this.courseTableData.find(el => el.id === editId);
              
                    if (this.editCourseSelectedRow.length < 1) {
                        this.setDialogMessage({
                            title: "操作錯誤",
                            message: "請至少剔選一個課堂",
                            isError: true,
                            returnLink: null,
                        });
                        this.setShowDialog(true);
                        this.$refs['editFormDialog'].setLoading(false);
                        return;
                    }

                    // - update selected date class
                    target.selected_lesson_id_list = this.editCourseSelectedRow.map(el => el.id);
                    target.dates = this.editCourseSelectedRow.map(el => el.date);
                    target.qty = target.selected_lesson_id_list.length;
                    
                } else if (this.currentTabType === 'product') {
                    target = this.productTableData.find(el => el.id === editId);
                    const newUpdate = this.editTableData.find(el => el.id === editId);
                    if (newUpdate && target) {
                        target.qty = newUpdate.qty;
                    }
                }

                if (target) {
                    target.discount_type = this.editDiscountType;
                    target.discount_value = this.$validate.regexFloating(this.editDiscountValue) ? parseFloat(this.editDiscountValue) : null;
                    target.discount_price = this.$validate.regexFloating(this.editDiscountPrice) ? parseFloat(this.editDiscountPrice) : null;
                    target.total = this.getUnitPrice(target) * target.qty;
                }
            }

            this.$refs['editFormDialog'].hide();
        },

        // ========= 付款 =========
        async handlePaymentSubmit() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);

            if (this.currentTabType === 'course' && !this.$validate.DataValid(this.courseSelectedRow) || 
                this.currentTabType === 'product' && !this.$validate.DataValid(this.productSelectedRow)) {
                    let errorMsg = '';
                    if (this.currentTabType === 'product') {
                        errorMsg = '請至少剔選一個產品';
                    } else {
                        errorMsg = '請至少剔選一個課程';
                    }

                    this.setDialogMessage({
                            title: "操作錯誤",
                            message: errorMsg,
                            isError: true,
                            returnLink: null,
                        });
                    this.setShowDialog(true);
                    this.$store.dispatch('setLoading', false);
                    return;
            }

            try {
                const { data } = await this.$Fetcher.GetStudents({
                    filter_page: 0,
                    filter_limit: 1,
                    filter_item: [{ key: 'id', value: this.studentData.student_id }]
                });

                if (this.$validate.DataValid(data[0]) && this.$validate.DataValid(data[0].order_total) && data[0].order_total > 0) {
                    this.submitPayment(false);
                } else {
                    this.submitPayment(true);
                }
            } catch(err) {
                this.$common.error(err);
                this.submitPayment(true);
            }
        },

        async submitPayment(isNew) {
            let payload = {
                student_id: this.studentData.student_id,
                school_id: this.studentData.school_id,
                remark: this.remark,
                payment_method: this.selectedPaymentMethod,
                payment_image: this.paymentImages.length ? this.paymentImages[0].file : '',
                lesson_id_list: [],
                product_id_list: [],
                subtotal: this.totalAmount,
                is_new: isNew,
                discount_type: '',
                discount_value: 0,
                status: this.selectedStatus
            };

            if (this.currentTabType === 'course') {
                const lessonList = [];
                this.courseSelectedRow.forEach(item => {
                    const targetRow = this.courseTableData.find(el => el.id === item.id);
                    if (targetRow) {
                        targetRow.selected_lesson_id_list.forEach(lessonId => {
                            let discount_value = null;
                            if (this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(targetRow.discount_value)) {
                                if (targetRow.discount_type === 'percentage') {
                                    discount_value = 100 - targetRow.discount_value;
                                } else if (targetRow.discount_type === 'fix_amount') {
                                    discount_value = targetRow.discount_value;
                                }
                            }

                            lessonList.push({
                                id: lessonId,
                                discount_type: this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value) ? targetRow.discount_type : '',
                                discount_value: this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value) ? discount_value : 0,
                            })
                        })

                    }
                });

                payload.lesson_id_list = lessonList;
                this.$common.logData(payload.lesson_id_list, '購買課堂');

            } else if (this.currentTabType === 'product') {
                payload.product_id_list = this.productSelectedRow.map(item => {
                    const targetRow = this.productTableData.find(el => el.id === item.id);
                    if (targetRow) {
                        let discount_value = null;
                        if (this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(targetRow.discount_value)) {
                            if (targetRow.discount_type === 'percentage') {
                                discount_value = 100 - targetRow.discount_value;
                            } else if (targetRow.discount_type === 'fix_amount') {
                                discount_value = targetRow.discount_value;
                            }
                        }
                        return {
                            id: targetRow.id,
                            amount: targetRow.qty,
                            discount_type: this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value) ? targetRow.discount_type : '',
                            discount_value: this.$validate.DataValid(targetRow.discount_type) && this.$validate.DataValid(discount_value) ? discount_value : 0,
                        }
                    } else {
                        return null;
                    }
                })

                payload.product_id_list = payload.product_id_list.filter(el => el !== null);
                this.$common.logData(payload.product_id_list, '購買商品');
            }

            try {
                await this.$Fetcher.NewOrder(payload);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '付款成功，可從訂單紀錄查閱近期帳單',
                    type: 'success',
                    refresh: false,
                    redirect: 'PayTuitionList'
                });

            } catch(err) {
                this.$common.error(err);

                this.setDialogMessage({
                    title: "付款失敗",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);

            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },


        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: PayTuitionPayment');
            if (this.$validate.DataValid(this.id)) {
                this.getPayTuitionData();
            } else {
                this.setDialogMessage({
                    message: 'noData',
                    returnLink: {name: 'PayTuitionList'}
                });
                this.setShowDialog(true);
                this.$store.dispatch('toggleLoadingPage', false);
            }
        }
    },

    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.$store.dispatch('toggleLoadingPage', true);
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else {
            next();
        }
    },
}
</script>

<style lang="scss" scoped>
.order-card-title {
    background-color: #E2E2E2;
}

.details-row {
    justify-content: space-between;
    & * {
        color: var(--v-colorBlack-base);
        font-size: .875rem;
        font-weight: 500;
    }
}
</style>