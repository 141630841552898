<template>
    <v-container fluid>
        <PageHeaderSlot>
            <template slot="top-right-slot">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateClass' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增課堂
                </v-btn>
            </template>
        </PageHeaderSlot>

        <v-card class="custom-calendar custom-table">
            <v-toolbar flat class="custom-table-toolbar" height="auto">
                <v-toolbar-items class="toolbar-left-items flex-grow-1">
                    <FormDatePicker :dateValue.sync="filter.date" dense hideDetails
                        customInputClass="mr-3 mt-2 mb-3 search-input-field" placeholder="搜尋日期" :readonly="tableLoading" @changed="onSearchDateChange($event)">
                    </FormDatePicker>

                    <FormSelect :fieldValue.sync="filter.course" dense hideDetails class="mr-3 mt-2 mb-3 search-input-field"
                        placeholder="課程" :options="courseOptions" @changed="search()" :readonly="tableLoading"></FormSelect>

                    <FormSelect :fieldValue.sync="filter.tutor" dense hideDetails class="mr-3 mt-2 mb-3 search-input-field"
                        placeholder="課程導師" :options="tutorOptions" @changed="search()" :readonly="tableLoading">
                    </FormSelect>
                </v-toolbar-items>

                <v-spacer></v-spacer>

                <v-toolbar-items class="toolbar-right-items">
                    <v-btn text small color="error" @click="clearSearch()" :class="{ 'pointer-loading': tableLoading }">
                        <v-icon class="mr-1" color="error" size="18"> {{ icon.mdiClose }} </v-icon>
                        清除搜尋
                    </v-btn>

                    <v-btn text small color="primary" @click="refreshData()" :class="{ 'pointer-loading': tableLoading }">
                        <v-icon class="mr-1" color="primary" size="18"> {{ icon.mdiRefresh }} </v-icon>
                        重新載入
                    </v-btn>

                    <v-btn text small color="success" target="_blank" rel="noreferrer noopenner" @click="exportData()"
                        :class="{ 'pointer-loading': tableLoading }">
                        <v-icon class="mr-1" color="success" size="18"> {{ icon.mdiFileTableOutline }} </v-icon>
                        匯出報表
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <CustomTimetable ref="timetable" :loading="tableLoading" :timetableData="timetableData"
                @dates-update="getTimetable($event)" @type-update="updateCalendarType($event)"></CustomTimetable>
        </v-card>
    </v-container>
</template>

<script>

import {
    mdiClose,
    mdiRefresh,
    mdiFileTableOutline,
    mdiChevronLeft,
    mdiChevronRight,
    mdiPlusCircleOutline
} from '@mdi/js'
import FormDatePicker from '@/components/formField/FormDatePicker.vue';
import FormSelect from '@/components/formField/FormSelect.vue';
import CustomTimetable from '@/components/CustomTimetable.vue';
import ExcelMixin from '@/mixins/ExcelMixin.vue';

export default {
    name: 'Timetable',
    mixins: [ExcelMixin],
    components: {
        FormDatePicker,
        FormSelect,
        CustomTimetable
    },
    computed: {
        query() {
            return this.$route.query;
        },
    },
    data: () => ({
        icon: {
            mdiClose,
            mdiRefresh,
            mdiFileTableOutline,
            mdiChevronLeft,
            mdiChevronRight,
            mdiPlusCircleOutline
        },
        filter: {
            date: '',
            course: '',
            tutor: '',
            dateList: [],
            calendarType: '',
        },

        courseOptions: [],
        tutorOptions: [],

        tableLoading: false,
        timetableData: [],

        exportLoading: false,
    }),
    methods: {
        async getPayload() {
            let payload = {
                join_tutor_data: true,
            };

            const selectedSchool = await this.getUserSelectedSchool();
            if (this.$validate.DataValid(selectedSchool)) {
                payload.center_id = selectedSchool;
            }

            const filterItems = [];
            if (this.$validate.DataValid(this.filter.dateList)) {
                if (this.filter.dateList.length > 1) {
                    filterItems.push({ key: 'start_date', value: this.filter.dateList[0] });
                    filterItems.push({ key: 'end_date', value: this.filter.dateList[this.filter.dateList.length - 1] });
                } else {
                    filterItems.push({ key: 'date', value: this.filter.dateList[0] });
                    payload['join_student_data'] = true;
                }
            }
           

            if (this.$validate.DataValid(this.filter.tutor)) {
                filterItems.push({ key: 'staff_id', value: parseInt(this.filter.tutor) });
            }

            if (this.$validate.DataValid(this.filter.course)) {
                filterItems.push({ key: 'course_id', value: parseInt(this.filter.course) });
            }

            if (this.$validate.DataValid(filterItems)) {
                payload.filter_item = filterItems;
            }
          

            return payload;
        },
        async getTimetable(dateList, regenUrl = true) {
            if (this.$validate.DataValid(dateList)) {
                this.filter.dateList = dateList;
            }

            if (regenUrl === true) {
                this.regenerateURL();
            }
           

            this.tableLoading = true;
            try {
                const payload = await this.getPayload();
                const { data } = await this.$Fetcher.GetLessons(payload);
                this.timetableData = data.map(el => {
                    const studentData = [];
                    for (let i = 0; i < 3; i++) {
                        const student = el.attendance_list[i];
                        if (this.$validate.DataValid(student)) {
                            studentData.push({
                                gender: student.gender,
                                name: student.student_name,
                                attendance: student.status
                            })
                        }
                    }

                    return {
                        id: el.id,
                        date: el.date,
                        tutor: this.$validate.DataValid(el.tutor_data) ? el.tutor_data.name : '',
                        current_student_num: el.current_student_num || 0,
                        max_student: el.max_student,
                        color: el.color,
                        grade: el.grade,
                        course_name: el.course_name,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        classroom: this.$validate.DataValid(el.classroom_data) ? el.classroom_data.name : '',
                        student_data: studentData
                    }
                })

            } catch (err) {
                this.$common.error(err);
                this.timetableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        updateCalendarType(val) {
            this.filter.calendarType = val;
        },
        onSearchDateChange(val) {
            if (this.tableLoading) {
                return;
            }

            const todayStr = this.$formatter.formatDate(new Date());
            if (!this.$validate.DataValid(val)) {
                this.$refs.timetable.setCurrentValue(todayStr);
            } else {
                this.$refs.timetable.setCurrentValue(val);
            }

            this.$refs.timetable.setCalendarType('day');
            const dateList = this.$refs.timetable.getDatesList();
            this.$refs.timetable.setDateList(dateList);
            this.getTimetable(dateList);
        },
        search() {
            if (this.tableLoading) {
                return;
            }
            this.getTimetable();
        },
        clearSearch() {
            if (this.tableLoading) {
                return;
            }
            this.filter.course = '';
            this.filter.tutor = '';
            this.filter.date = '';
            this.onSearchDateChange();
        },
        refreshData() {
            if (this.tableLoading) {
                return;
            }
            this.onSearchDateChange(this.filter.date);
        },
        async exportData() {
            if (this.exportLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.exportLoading = true;

            try {
                const header = {
                   date: '課堂日期',
                   course_name: '課程名稱',
                   start_time: '課堂開始時間',
                   end_time: '課堂結束時間',
                   grade: '年級',
                   tutor: '導師',
                   classroom: '課室',
                   student_num: '人數',
                }
                const payload = await this.getPayload();
                const { data } = await this.$Fetcher.GetLessons(payload);
                const exportData = data.map(el => {
                    return {
                        date: el.date,
                        course_name: el.course_name,
                        start_time: el.start_time,
                        end_time: el.end_time,
                        grade: this.$grade[el.grade],
                        tutor: el.tutor_data ? el.tutor_data.name : '',
                        classroom: el.classroom_data ? el.classroom_data.name : '',
                        student_num: `${el.current_student_num} / ${el.max_student}`
                    };
                });

                this.exportExcel(
                    header,
                    exportData,
                    '課程時間表',
                    `課程時間表_${this.$formatter.formatDate(new Date()).replace(/-/g, '')}.xlsx`,
                );
            } catch(err) {
                this.$common.error(err);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: '匯出失敗',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
            } finally {
                this.exportLoading = false;
            }
        },


        async getTutorOptions() {
            try {
                let payload = {};
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center = [selectedSchool];
                }

                const { data } = await this.$Fetcher.GetTutors(payload);
                this.tutorOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: el.name
                    }
                })

            } catch { 
            } finally {
                await this.getCourseOptions();
            }
        },
        async getCourseOptions() {
            try {
                let payload = {
                    join_category_data: true
                };
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center = [selectedSchool];
                }

                const { data } = await this.$Fetcher.GetCourses(payload);
                this.courseOptions = data.map(el => {
                    return {
                        value: el.id,
                        text: this.$formatter.generateCourseTitle(el)
                    }
                })

            } catch { }
        },


        async getQuery() {
            if (Object.keys(this.query).length > 0) {
                const todayStr = this.$formatter.formatDate(new Date());

                if (this.$validate.DataValid(this.query.date) && this.$validate.regexDateStr(this.query.date)) {
                    this.filter.date = this.query.date;
                }

                if (this.$validate.DataValid(this.query.start_date) && this.$validate.regexDateStr(this.query.start_date)) {
                    this.$refs.timetable.setCurrentValue(this.query.start_date);
                } else {
                    this.$refs.timetable.setCurrentValue(todayStr);
                }

                if (this.query.type === 'week') {
                    this.$refs.timetable.setCalendarType('week');
                } else {
                    this.$refs.timetable.setCalendarType('day');
                }

                this.filter.dateList = this.$refs.timetable.getDatesList();
                this.$refs.timetable.setDateList(this.filter.dateList);

                if (this.$validate.DataValid(this.query.course) && this.$validate.regexNumber(this.query.course)) {
                    this.filter.course = parseInt(this.query.course);
                }

                if (this.$validate.DataValid(this.query.tutor) && this.$validate.regexNumber(this.query.tutor)) {
                    this.filter.tutor = parseInt(this.query.tutor);
                }
            }

            await this.getTimetable(this.filter.dateList, false);
            await this.getTutorOptions();
        },

        regenerateURL() {  
            const todayStr = this.$formatter.formatDate(new Date());
            const q = {
                date: this.filter.date,
                start_date: this.filter.dateList[0] && this.filter.dateList[0] !== todayStr ? this.filter.dateList[0] : '',
                type: this.filter.calendarType !== 'day' ? this.filter.calendarType : '',
                course: this.filter.course,
                tutor: this.filter.tutor
            };

            const newQuery = this.$common.diffQuery(q, this.query);
            if (newQuery) {
                this.$router.replace({ query: newQuery });
            }
        },

        async handleSiteLoaded() {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> handleSiteLoaded: Timetable');
            this.getQuery();
        }
    },

    destroyed() {
        window.removeEventListener('onSiteLoaded', this.handleSiteLoaded)
    },
    mounted() {
        this.tableLoading = true;
        window.addEventListener('onSiteLoaded', this.handleSiteLoaded);
    },
}
</script>